import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Box, Typography } from '@mui/material';

interface CkEditorComponentProps {
  label: string;
  value: string;
  onChange: (data: string) => void;
  error?: boolean;
  helperText?: string;
}

const CkEditorComponent: React.FC<CkEditorComponentProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="8px" width="100%">
      <Typography variant="caption" color={'textPrimary'}>
        {label}
      </Typography>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        onChange={(event, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        config={{
          placeholder: 'Enter text...',
          toolbar: {
            items: [
              'undo',
              'redo',
              '|',
              'heading',
              '|',
              'bold',
              'italic',
              '|',
              'link',
              'bulletedList',
              'numberedList',
              '|',
              'alignment',
              'indent',
              'outdent',
              '|',
              'blockQuote',
              'alignment',
            ],
            shouldNotGroupWhenFull: true,
          },
          alignment: {
            options: ['left', 'center', 'right', 'justify'],
          },
        }}
        onReady={editor => {
          const rootElement = editor.editing.view.document.getRoot();

          if (rootElement) {
            editor.editing.view.change(writer => {
              writer.setStyle('min-height', '300px', rootElement);
            });
          }

          const editorElement = editor.ui.view.editable.element;
          if (editorElement) {
            editorElement.style.minHeight = '300px';
          }
        }}
      />
      {error && (
        <Typography variant="caption" color="error" margin={'0 14px'}>
          {helperText}
        </Typography>
      )}
    </Box>
  );
};

export default CkEditorComponent;
