import React, { useEffect, useState, useRef } from 'react';
import {
  Autocomplete,
  FormControl,
  TextField,
  Typography,
  Box,
  useMediaQuery,
} from '@mui/material';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import {
  useGetSpecialitiesByUniversityQuery,
  useGetUniversitiesQuery,
} from '../../../../store/apiSlice/universitySpecialityApi';
import DateInput from '../../../MUIComponents/DateInput';
import AutocompleteOption from '../../../shared/AutocompleteOption/AutocompleteOption';
import ArrowDownIcon from '../../../Icons/ArrowDownIcon';
import { ProfilelInfoFormInputs } from '../../../../interfaces/profile.interface';

interface ProfileEducationFormProps {
  control: Control<ProfilelInfoFormInputs, any>;
  setValue: UseFormSetValue<ProfilelInfoFormInputs>;
  watch: UseFormWatch<ProfilelInfoFormInputs>;
  errors: FieldErrors<ProfilelInfoFormInputs>;
  index: number;
}

type EducationErrors = {
  universityName?: FieldError;
  specialtyName?: FieldError;
  startYear?: FieldError;
  endYear?: FieldError;
};

const ProfileEducationForm: React.FC<ProfileEducationFormProps> = ({
  control,
  setValue,
  watch,
  errors,
  index,
}) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const educationErrors = errors?.educations?.[index] as
    | EducationErrors
    | undefined;

  // Track search inputs
  const [universitySearch, setUniversitySearch] = useState('');
  const [specialtySearch, setSpecialtySearch] = useState('');

  const selectedUniversity = watch(`educations.${index}.universityName`);
  const selectedUniversityId = watch(`educations.${index}.idUniversity`);
  const selectedSpecialty = watch(`educations.${index}.specialtyName`);
  const selectedSpecialtyId = watch(`educations.${index}.idSpecialty`);

  // Fetch universities based on search input
  const { data: universitiesResponse, isLoading: isUniversitiesLoading } =
    useGetUniversitiesQuery(
      { page: 1, limit: 9000, search: universitySearch, sort: 'asc' },
      { refetchOnMountOrArgChange: true }
    );

  const universities = universitiesResponse?.data || [];

  // Fetch specialties dynamically based on university ID and search
  const {
    data: specialitiesResponse,
    isLoading: isSpecialitiesLoading,
    refetch,
  } = useGetSpecialitiesByUniversityQuery(
    {
      universityId: selectedUniversityId || '',
      page: 1,
      limit: 9000,
      search: specialtySearch,
      sort: 'asc',
    },
    { skip: !selectedUniversityId }
  );

  const specialities = selectedUniversityId
    ? specialitiesResponse?.data || []
    : [];

  useEffect(() => {
    if (selectedUniversityId && universities.length) {
      const existingUniversity = universities.find(
        uni => uni.id === selectedUniversityId
      );
      if (existingUniversity) {
        setValue(`educations.${index}.universityName`, existingUniversity.name);
        setValue(`educations.${index}.idUniversity`, existingUniversity.id);
      }
    }

    if (selectedSpecialtyId && specialities.length) {
      const existingSpecialty = specialities.find(
        spec => spec.id === selectedSpecialtyId
      );
      if (existingSpecialty) {
        setValue(`educations.${index}.specialtyName`, existingSpecialty.name);
        setValue(`educations.${index}.idSpecialty`, existingSpecialty.id);
      }
    }
  }, [
    selectedUniversityId,
    selectedSpecialtyId,
    universities,
    specialities,
    setValue,
    index,
  ]);

  useEffect(() => {
    if (selectedUniversityId) {
      refetch();
    }
  }, [selectedUniversityId, refetch]);

  return (
    <Box
      mt="48px"
      display="flex"
      flexDirection="column"
      gap={mobileView ? '28px' : '36px'}
    >
      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        {/* University Select / Search */}
        <Controller
          name={`educations.${index}.universityName`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!educationErrors?.universityName}>
              <Typography variant="caption" sx={{ mb: '8px' }}>
                University:
              </Typography>
              <Autocomplete
                freeSolo
                options={universities}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.name
                }
                loading={isUniversitiesLoading}
                value={
                  universities.find(uni => uni.id === selectedUniversityId)
                    ?.name ||
                  selectedUniversity ||
                  ''
                }
                onInputChange={(_, newInputValue) => {
                  setUniversitySearch(newInputValue);
                  setValue(`educations.${index}.universityName`, newInputValue);
                  setValue(`educations.${index}.idUniversity`, '');
                  setValue(`educations.${index}.specialtyName`, '');
                  setValue(`educations.${index}.idSpecialty`, '');
                }}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue(`educations.${index}.universityName`, newValue);
                    setValue(`educations.${index}.idUniversity`, '');
                  } else {
                    setValue(
                      `educations.${index}.universityName`,
                      newValue?.name || ''
                    );
                    setValue(`educations.${index}.idUniversity`, newValue?.id);
                  }
                  setUniversitySearch('');
                  setValue(`educations.${index}.specialtyName`, '');
                  setValue(`educations.${index}.idSpecialty`, '');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Choose or enter university"
                    error={!!educationErrors?.universityName}
                    helperText={educationErrors?.universityName?.message || ''}
                  />
                )}
                renderOption={(props, option) => (
                  <AutocompleteOption text={option.name} props={props} />
                )}
                sx={{
                  '.MuiInputBase-root': { height: '50px' },
                  '.MuiSvgIcon-root': { bgcolor: 'transparent !important' },
                }}
                popupIcon={<ArrowDownIcon />}
              />
            </FormControl>
          )}
        />

        {/* Specialty Select / Search */}
        <Controller
          name={`educations.${index}.specialtyName`}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth error={!!educationErrors?.specialtyName}>
              <Typography variant="caption" sx={{ mb: '8px' }}>
                Specialty:
              </Typography>
              <Autocomplete
                freeSolo
                options={specialities}
                getOptionLabel={option =>
                  typeof option === 'string' ? option : option.name
                }
                loading={isSpecialitiesLoading}
                value={
                  specialities.find(spec => spec.id === selectedSpecialtyId)
                    ?.name ||
                  selectedSpecialty ||
                  ''
                }
                onInputChange={(_, newInputValue) => {
                  setSpecialtySearch(newInputValue);
                  setValue(`educations.${index}.specialtyName`, newInputValue);
                  setValue(`educations.${index}.idSpecialty`, '');
                }}
                onChange={(_, newValue) => {
                  if (typeof newValue === 'string') {
                    setValue(`educations.${index}.specialtyName`, newValue);
                    setValue(`educations.${index}.idSpecialty`, '');
                  } else {
                    setValue(
                      `educations.${index}.specialtyName`,
                      newValue?.name || ''
                    );
                    setValue(`educations.${index}.idSpecialty`, newValue?.id);
                  }
                  setSpecialtySearch('');
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder="Choose or enter specialty"
                    error={!!educationErrors?.specialtyName}
                    helperText={educationErrors?.specialtyName?.message || ''}
                  />
                )}
                renderOption={(props, option) => (
                  <AutocompleteOption text={option.name} props={props} />
                )}
                sx={{
                  '.MuiInputBase-root': { height: '50px' },
                  '.MuiSvgIcon-root': { bgcolor: 'transparent !important' },
                }}
                popupIcon={<ArrowDownIcon />}
              />
            </FormControl>
          )}
        />
      </Box>

      <Box
        display="flex"
        flexDirection={mobileView ? 'column' : 'row'}
        gap={mobileView ? '28px' : '22px'}
      >
        {/* Start Year */}
        <Box flex="1">
          <DateInput<ProfilelInfoFormInputs>
            control={control}
            name={`educations.${index}.startYear`}
            label="Start of education:"
            error={educationErrors?.startYear}
            helperText={educationErrors?.startYear?.message || ''}
          />
        </Box>

        {/* End Year */}
        <Box flex="1">
          <DateInput<ProfilelInfoFormInputs>
            control={control}
            name={`educations.${index}.endYear`}
            label="End of education:"
            error={educationErrors?.endYear}
            helperText={educationErrors?.endYear?.message || ''}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileEducationForm;
