import React, { useState } from 'react';
import { colors } from '../../theme/theme';
import {
  Box,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import AddGreyIcon from '../Icons/AddGreyIcon';
import DeleteFileRedIcon from '../Icons/DeleteFileRedIcon';
import { useFieldArray, useForm } from 'react-hook-form';
import CustomButton from '../MUIComponents/CustomButton';
import InviteIcon from '../Icons/InviteIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { emailsSchema } from '../../validation/user-validation';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import SnackbarCustom from '../MUIComponents/SnackbarCustom';
import { errorHelper } from '../../utils/helper/error-helper';
import { useInviteUsersByEmailsMutation } from '../../store/apiSlice/auth/authApi';
import IconWithBackground from '../MUIComponents/IconWithBackground';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface FormValues {
  emails: { value: string }[];
}

const SendInvite: React.FC = () => {
  const { theme } = useThemeContext();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const {
    register,
    control,
    handleSubmit,
    setError,
    clearErrors,
    getValues,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      emails: [{ value: '' }],
    },
    mode: 'onChange',
    resolver: yupResolver(emailsSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const [inviteUsersByEmails, { isLoading: isInviteLoading }] =
    useInviteUsersByEmailsMutation();

  const onSubmitUser = async (data: FormValues) => {
    try {
      const response = await inviteUsersByEmails({
        emails: data.emails.map(e => e.value),
      }).unwrap();
      setSnackbarMessage(`${response?.data}`);
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
      setSnackbarOpen(true);
    } catch (error) {
      const errorMessage = errorHelper(error);
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    }
  };

  const handleAddEmail = async () => {
    const values = getValues('emails');
    const lastEmail = values[values.length - 1].value;
    const isLastEmailValid = await trigger(`emails.${values.length - 1}.value`);

    const isDuplicate = values.some(
      (email, index) => email.value === lastEmail && index !== values.length - 1
    );

    if (lastEmail.trim() === '' || !isLastEmailValid || isDuplicate) {
      const errorMessage = isDuplicate
        ? 'This email is already added. Please enter a unique email.'
        : 'Please fill in the email correctly.';
      setError(`emails.${values.length - 1}.value`, {
        type: 'manual',
        message: errorMessage,
      });
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    } else {
      clearErrors(`emails.${values.length - 1}.value`);
      append({ value: '' });
    }
  };

  const handleChangeEmail = async (fieldIndex: number) => {
    const values = getValues('emails');
    const currentEmail = values[fieldIndex].value;

    const isDuplicate = values.some(
      (email, index) => email.value === currentEmail && index !== fieldIndex
    );

    if (isDuplicate) {
      const errorMessage =
        'This email is already added. Please enter a unique email.';
      setError(`emails.${fieldIndex}.value`, {
        type: 'manual',
        message: errorMessage,
      });
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      setSnackbarOpen(true);
    } else {
      clearErrors(`emails.${fieldIndex}.value`);
    }
  };

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      gap="3rem"
      bgcolor={'background.white'}
    >
      <Typography variant="h2">Expand your Free Agent network!</Typography>
      <Box width="100%" display="flex" flexDirection="column" gap="36px">
        <Box width="100%" display="flex" flexDirection="column" gap="16px">
          <Typography variant="h3">
            Invite fellow athletes to the Free Agent community.
          </Typography>
          <Typography variant="body3">
            Referring other athletes boosts your profile visibility and unlocks
            future opportunities and career potential.
          </Typography>
        </Box>
        <Box
          width="100%"
          display="flex"
          height="50px"
          padding="0 16px"
          alignItems="center"
          bgcolor={'background.mint'}
        >
          <Typography variant="body3" fontWeight={700} color={'text.done'}>
            You have {fields.length} invite{fields.length > 1 ? 's' : ''} to
            send
          </Typography>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="12px">
          <Typography variant="body4" fontWeight={700} p={'0 2px'}>
            Email:
          </Typography>
          <Box width="100%" display="flex" flexDirection="column" gap="16px">
            {fields.map((field, fieldIndex) => (
              <Box
                key={field.id}
                display="flex"
                alignItems="flex-start"
                gap="8px"
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Enter email address"
                  {...register(`emails.${fieldIndex}.value`, {
                    onBlur: () => handleChangeEmail(fieldIndex),
                  })}
                  error={!!errors.emails?.[fieldIndex]?.value}
                  helperText={errors.emails?.[fieldIndex]?.value?.message || ''}
                />
                <IconButton
                  onClick={() => {
                    if (fieldIndex === fields.length - 1) {
                      handleAddEmail();
                    } else {
                      remove(fieldIndex);
                      clearErrors(`emails.${fieldIndex}.value`);
                    }
                  }}
                  className={
                    fieldIndex === fields.length - 1 ? 'add' : 'delete'
                  }
                  disableRipple={fieldIndex === fields.length - 1}
                >
                  {fieldIndex === fields.length - 1 ? (
                    <IconWithBackground icon={<AddGreyIcon />} />
                  ) : (
                    <IconWithBackground
                      bgcolor={'button.light'}
                      icon={<DeleteFileRedIcon />}
                    />
                  )}
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" gap="8px" justifyContent="right">
        <CustomButton
          type="submit"
          icon={<InviteIcon />}
          buttonWidth={mobileView ? '100%' : '220px'}
          onClick={handleSubmit(onSubmitUser)}
          disabled={!isValid || isInviteLoading}
        >
          {isInviteLoading ? 'Sending...' : 'Send invites'}
        </CustomButton>
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default SendInvite;
