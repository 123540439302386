import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface JobIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const JobsIcon = ({ iconColor, active, ...svgProps }: JobIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <path d="M5 8H23V22H5V8Z" stroke={color} strokeWidth="1.5" />
      <path
        d="M10 8V5C10 4 11 3 12 3H16C17 3 18 4 18 5V8"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M5 14H23" stroke={color} strokeWidth="1.5" />
      <path d="M14 14V17" stroke={color} strokeWidth="1.5" />
    </SvgIcon>
  );
};

export default JobsIcon;
