import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authApi from './apiSlice/auth/authApi';
import authSlice from './apiSlice/auth/authSlice';
import sportApi from './apiSlice/sportApi';
import userApi from './apiSlice/userApi';
import userSportBackgroundApi from './apiSlice/sportBackgroundApi';
import teamApi from './apiSlice/teamApi';
import postApi from './apiSlice/post/postApi';
import friendApi from './apiSlice/friendApi';
import friendInvitationApi from './apiSlice/friendInvitationApi';
import userFilesApi from './apiSlice/userFilesApi';
import userEducationApi from './apiSlice/userEducationApi';
import notificationApi from './apiSlice/notificationApi';
import careerApi from './apiSlice/careerApi';
import jobpostApi from './apiSlice/jobpost/jobpostApi';
import userCompanyStructureApi from './apiSlice/userCompanyStructure';
import chatApi from './apiSlice/chat/chatApi';
import chatSlice from './apiSlice/chat/chatSlice';
import groupChatApi from './apiSlice/chat/groupApi';
import profileMediaApi from './apiSlice/profileMediaApi';
import jobUserApplyApi from './apiSlice/jobpost/jobUserApplyApi';
import postReactionApi from './apiSlice/post/postReactionApi';
import commentApi from './apiSlice/post/commentApi';
import commentReactionApi from './apiSlice/post/commentReactionApi';
import friendIntroductionApi from './apiSlice/friendIntroductionApi';
import userCompanyInfoApi from './apiSlice/userCompanyInfoApi';
import subscriptionApi from './apiSlice/subscription/subscriptionApi';
import analyticApi from './apiSlice/analitics/analiticsApi';
import reportApi from './apiSlice/analitics/companyReportApi';
import individualStageApi from './apiSlice/individualStageApi';
import reviewApi from './apiSlice/reviewApi';
import searchApi from './apiSlice/searchApi';
import partnershipApi from './apiSlice/partnershipApi';
import quizApi from './apiSlice/quizApi';
import universitySpecialityApi from './apiSlice/universitySpecialityApi';

const reducers = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  auth: authSlice,
  [careerApi.reducerPath]: careerApi.reducer,
  [sportApi.reducerPath]: sportApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [quizApi.reducerPath]: quizApi.reducer,
  [userSportBackgroundApi.reducerPath]: userSportBackgroundApi.reducer,
  [teamApi.reducerPath]: teamApi.reducer,
  [postApi.reducerPath]: postApi.reducer,
  [friendApi.reducerPath]: friendApi.reducer,
  [partnershipApi.reducerPath]: partnershipApi.reducer,
  [friendIntroductionApi.reducerPath]: friendIntroductionApi.reducer,
  [friendInvitationApi.reducerPath]: friendInvitationApi.reducer,
  [userFilesApi.reducerPath]: userFilesApi.reducer,
  [userEducationApi.reducerPath]: userEducationApi.reducer,
  [notificationApi.reducerPath]: notificationApi.reducer,
  [jobpostApi.reducerPath]: jobpostApi.reducer,
  [postReactionApi.reducerPath]: postReactionApi.reducer,
  [commentApi.reducerPath]: commentApi.reducer,
  [userCompanyStructureApi.reducerPath]: userCompanyStructureApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [groupChatApi.reducerPath]: groupChatApi.reducer,
  chat: chatSlice,
  [profileMediaApi.reducerPath]: profileMediaApi.reducer,
  [jobUserApplyApi.reducerPath]: jobUserApplyApi.reducer,
  [commentReactionApi.reducerPath]: commentReactionApi.reducer,
  [userCompanyInfoApi.reducerPath]: userCompanyInfoApi.reducer,
  [subscriptionApi.reducerPath]: subscriptionApi.reducer,
  [analyticApi.reducerPath]: analyticApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [individualStageApi.reducerPath]: individualStageApi.reducer,
  [reviewApi.reducerPath]: reviewApi.reducer,
  [searchApi.reducerPath]: searchApi.reducer,
  [universitySpecialityApi.reducerPath]: universitySpecialityApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      authApi.middleware,
      careerApi.middleware,
      sportApi.middleware,
      userApi.middleware,
      quizApi.middleware,
      userSportBackgroundApi.middleware,
      teamApi.middleware,
      postApi.middleware,
      friendApi.middleware,
      partnershipApi.middleware,
      friendIntroductionApi.middleware,
      friendInvitationApi.middleware,
      userFilesApi.middleware,
      userEducationApi.middleware,
      notificationApi.middleware,
      jobpostApi.middleware,
      postReactionApi.middleware,
      commentApi.middleware,
      userCompanyStructureApi.middleware,
      chatApi.middleware,
      groupChatApi.middleware,
      profileMediaApi.middleware,
      jobUserApplyApi.middleware,
      commentReactionApi.middleware,
      userCompanyInfoApi.middleware,
      subscriptionApi.middleware,
      analyticApi.middleware,
      reportApi.middleware,
      individualStageApi.middleware,
      reviewApi.middleware,
      searchApi.middleware,
      universitySpecialityApi.middleware
    ),
});

export type TypeRootState = ReturnType<typeof store.getState>;
