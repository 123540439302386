import { Box, useMediaQuery } from '@mui/material';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ButtonsOverlay from '../../../components/Media/Layouts/ButtonsOverlay';
import MediaPreviewModal from '../../../components/Media/Modals/MediaPreviewModal';
import { ItemData } from '../../../components/MUIComponents/QuiltedImageList';
import { useModal } from '../../../hooks/useModal';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface PhotoGridProps {
  photo: ItemData;
  photos: ItemData[];
}

const srcset = (image: string, size: number, rows = 1, cols = 1) => {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
};

const PostPhoto = ({ photo, photos }: PhotoGridProps) => {
  const { isOpen, toggleModal } = useModal();
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const imgRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    if (imgRef.current) {
      const { width, height } = dimensions;
      imgRef.current.style.aspectRatio = `${width / height < 0.7 ? '' : 'auto'} 4 / ${5}`;
    }
  }, [dimensions]);

  const handleOpen = (index: number) => {
    setCurrentIndex(index);
    toggleModal();
  };

  const handleNext = () => {
    setCurrentIndex(prevIndex =>
      prevIndex < photos.length - 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex(prevIndex =>
      prevIndex > 0 ? prevIndex - 1 : photos.length - 1
    );
  };

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    const { naturalWidth, naturalHeight } = event.target as HTMLImageElement;

    setDimensions({
      width: naturalWidth,
      height: naturalHeight,
    });
  };

  return (
    <Box sx={{ textAlign: 'center' }}>
      <Box
        sx={theme => ({
          width: '100%',
          height: '100%',
          position: 'relative',
          backgroundColor: theme.palette.background.lightDark,
          '&:hover .photo-buttons-overlay': { opacity: 1 },
        })}
        onClick={
          !tabletAndBigger
            ? () =>
                handleOpen(
                  photos.findIndex(photoElem => photoElem.id === photo.id)
                )
            : undefined
        }
      >
        <Box
          ref={imgRef}
          component="img"
          {...srcset(photo.img, 121, photo.rows, photo.cols)}
          alt={photo.title}
          loading="lazy"
          onLoad={handleImageLoad}
          sx={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
        <ButtonsOverlay
          nameOfClass="photo-buttons-overlay"
          openHandler={() =>
            handleOpen(photos.findIndex(photoElem => photoElem.id === photo.id))
          }
          hasRemove={false}
        />
      </Box>
      <MediaPreviewModal
        files={photos.map(photo => {
          return {
            id: photo.id,
            fileUrl: photo.img,
            fileName: photo.title,
          };
        })}
        currentIndex={currentIndex}
        isOpen={isOpen}
        onClose={() => toggleModal()}
        clickNext={handleNext}
        clickPrev={handlePrev}
      />
    </Box>
  );
};

export default PostPhoto;
