import { Box, SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import remarkGfm from 'remark-gfm';
import { IPost } from '../../../../interfaces/post.interface';
import { useThemeContext } from '../../../../theme/ThemeContextProvider';
import { removeHashtagWords } from '../../../../utils/helper/removeHashtagWords';

interface PostBodyTextProps {
  post: IPost;
  sx?: SxProps;
}

const PostBodyText: FC<PostBodyTextProps> = ({ post, sx }) => {
  const { theme } = useThemeContext();

  return (
    <Box
      component="header"
      sx={{
        minHeight: post.repostedPostId ? '5.75rem' : 'auto',
        ...sx,
      }}
    >
      <Typography
        variant="body3"
        sx={{
          color: theme.palette.secondary.main,
          whiteSpace: 'pre-wrap',
          textAlign: 'left',
          lineHeight: '140%',
          '& > ol': {
            listStylePosition: 'inside',
          },
        }}
      >
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            a: ({ href, children, ...props }) => {
              if (!href) return <>{children}</>;

              const isExternalLink = /^https?:\/\//.test(href);
              const isPostLink = href.startsWith('/post/');

              return isExternalLink ? (
                <a
                  href={href}
                  target="_blank"
                  rel="noopener noreferrer"
                  {...props}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    variant="body3"
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    {children}
                  </Typography>
                </a>
              ) : (
                <Link to={isPostLink ? href : `/${href}`} {...props}>
                  <Typography
                    variant="body3"
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: 700,
                    }}
                  >
                    {children}
                  </Typography>
                </Link>
              );
            },
          }}
        >
          {removeHashtagWords(post.content ?? '')}
        </ReactMarkdown>
      </Typography>

      <Box
        sx={{
          marginTop: '1rem',
        }}
      >
        {post.tags.length > 0 &&
          post.tags.map((tag, index) => (
            <Link
              key={tag + index}
              to={`/global-search?query=${encodeURIComponent('#' + tag)}&search-type=post`}
            >
              <Typography
                variant="body3"
                sx={{
                  mt: 2,
                  color: theme.palette.primary.main,
                  fontWeight: 700,
                }}
              >
                #{tag} {` `}
              </Typography>
            </Link>
          ))}
      </Box>
    </Box>
  );
};

export default PostBodyText;
