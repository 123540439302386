import { Box, IconButton } from '@mui/material';
import Draft from 'draft-js';
import { useCallback, useRef } from 'react';
import SendIcon from '../../../../components/Icons/SendIcon';
import {
  EditorRef,
  Suggestion,
} from '../../../../components/shared/Mentions/MentionsTextField/MentionsTextField';
import MentionsTextFieldWrapper from '../../../../components/shared/Mentions/MentionsTextFieldWrapper/MentionsTextFieldWrapper';

interface CommentFieldProps {
  disabled?: boolean;
  commentMsg: string;
  sendComment: () => void;
  setMentions: (mentions: Suggestion[]) => void;
  setCommentMsg: (msg: string) => void;
}

const CommentField = ({
  disabled = false,
  commentMsg,
  setCommentMsg,
  setMentions,
  sendComment,
}: CommentFieldProps) => {
  const editorRef = useRef<EditorRef | null>(null);

  const clearTextFieldInput = useCallback(
    () => editorRef?.current?.updateEditorState(''),
    []
  );

  const keyBindingFn = useCallback(
    (e: React.KeyboardEvent): string | null | undefined => {
      // this ensures that textInput is single line
      if (!e.metaKey && e.code === 'Enter') {
        // Function to execute...
        return 'send-comment';
      }

      // these events are handled by mentionPlugin, in order to work it need return exactly 'undefined'
      // https://github.com/draft-js-plugins/draft-js-plugins/blob/master/FAQ.md#keybindings-added-by-a-plugin-stop-working-when-using-custom-keybindingfn-function
      // *************************************************
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        return undefined;
      }

      // escape
      if (e.key === 'Escape') {
        return undefined;
      }

      // tab
      if (e.key === 'Tab') {
        return undefined;
      }
      // *************************************************

      return Draft.getDefaultKeyBinding(e);
    },
    []
  );

  const handleKeyCommand = useCallback(
    (
      command: Draft.EditorCommand,
      editorState: Draft.EditorState,
      eventTimeStamp: number
    ): Draft.DraftHandleValue => {
      if (command === 'send-comment') {
        sendComment();
        // Clear the editor state
        clearTextFieldInput();
        // Do what you want to here, then tell Draft that we've taken care of this command
        return 'handled';
      }

      // This wasn't the 'delete-me' command, so we want Draft to handle it instead.
      // We do this by telling Draft we haven't handled it.
      return 'not-handled';
    },
    [clearTextFieldInput, sendComment]
  );

  return (
    <Box position="relative">
      <MentionsTextFieldWrapper
        ref={editorRef}
        height={55}
        sx={{
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            bottom: 0,
          },
          '& .editor-styles-custom': {
            height: 40,
            p: '10px 40px 10px 14px',
            position: 'absolute',
          },
        }}
        onInputChange={value => setCommentMsg(value)}
        setMentions={mentions => setMentions(mentions)}
        placeholder="Comment..."
        maxLength={1250}
        helperText={
          commentMsg.length === 1250 ? 'Maximum 1250 characters reached.' : ''
        }
        error={commentMsg.length === 1250}
        handleKeyCommand={handleKeyCommand}
        keyBindingFn={keyBindingFn as any}
        disabled={disabled}
      />
      <IconButton
        type="submit"
        aria-label="send comment button"
        sx={{
          width: '2.5rem',
          height: '2.5rem',
          minWidth: '2.5rem',
          minHeight: '2.5rem',
          padding: 0,

          position: 'absolute',
          top: 0,
          right: 0,
          zIndex: 1002,
        }}
        disabled={disabled}
        onClick={e => {
          e.preventDefault();
          sendComment();
          clearTextFieldInput();
        }}
      >
        <SendIcon />
      </IconButton>
    </Box>
  );
};

export default CommentField;
