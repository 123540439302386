import { Box, Menu, MenuItem, Typography } from '@mui/material';
import React, { memo } from 'react';
import { ButtonTypeEnum } from '../../../enums/button-type-enum';
import { INotification } from '../../../interfaces/notification.interface';
import CustomButton from '../../MUIComponents/CustomButton';
import NotificationMenuItem from './NotificationMenuItem';

interface INotificationListProps {
  notifications: INotification[];
  isLoadMore: boolean;
  anchorEl: any;
  open: boolean;
  onClose: () => void;
  onLoadMore: () => void;
  onDelete: (notificationId: string) => void;
  onMarkAsRead: () => void;
}

const NotificationList: React.FC<INotificationListProps> = ({
  notifications,
  isLoadMore,
  anchorEl,
  open,
  onClose,
  onLoadMore,
  onDelete,
  onMarkAsRead,
}) => (
  <Menu
    sx={{
      top: '30px',
      pt: '0px',
      pb: '0px',
      '& .css-tansqo-MuiButtonBase-root-MuiMenuItem-root+.MuiDivider-root': {
        marginTop: '0px',
        marginBottom: '0px',
      },
    }}
    id="show-more"
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    MenuListProps={{
      'aria-labelledby': 'show-more-button',
      disablePadding: true,
    }}
  >
    {notifications.length === 0 && (
      <Box sx={{ p: 2 }}>
        <Typography variant={'body3'}>No Notifications</Typography>
      </Box>
    )}

    {notifications.length > 0 &&
      notifications.map((notification: INotification) => (
        <NotificationMenuItem
          key={notification.id}
          notification={notification}
          onDelete={onDelete}
          onMarkAsRead={onMarkAsRead}
        />
      ))}

    {isLoadMore && (
      <MenuItem sx={{ paddingBottom: '12px' }}>
        <CustomButton
          fullWidth
          sx={{ width: '100%', color: 'text.primary' }}
          disabled={!isLoadMore}
          variant="outlined"
          variantType={ButtonTypeEnum.TERTIARY}
          onClick={onLoadMore}
        >
          See previous notifications
        </CustomButton>
      </MenuItem>
    )}
  </Menu>
);

export default memo(NotificationList);
