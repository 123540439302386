import { Box, BoxProps, useMediaQuery } from '@mui/material';
import PersonalInfo from '../../../components/Profile/PersonalInfo';
import ProfileSidebar from '../../../components/Profile/ProfileSidebar/ProfileSidebar';
import SidebarLayout from '../../../layouts/SidebarLayout';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import { Photos, Videos } from '../../../components/Media';
import SportBackgroundsSection from '../../../components/Profile/SportBackgroundsSection';
import { useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

const ProfilePage = () => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const location = useLocation();

  const personalInfoRef = useRef<HTMLDivElement | null>(null);
  const photosRef = useRef<HTMLDivElement | null>(null);
  const videosRef = useRef<HTMLDivElement | null>(null);
  const sportBackgroundRef = useRef<HTMLDivElement | null>(null);

  const [isDataLoaded, setIsDataLoaded] = useState({
    personalInfo: false,
    photos: false,
    videos: false,
    sportBackground: false,
  });

  const mobileProps: BoxProps = mobileView
    ? {
        flexDirection: 'column',
        marginTop: { xs: '-20px', md: '-24px' },
      }
    : {};

  const loadData = async (section: string) => {
    await new Promise(resolve => setTimeout(resolve, 500));
    setIsDataLoaded(prev => ({ ...prev, [section]: true }));
  };

  useEffect(() => {
    loadData('personalInfo');
    loadData('photos');
    loadData('videos');
    loadData('sportBackground');
  }, []);

  useEffect(() => {
    const scrollToSection = () => {
      if (
        location.hash === '#personal-info' &&
        personalInfoRef.current &&
        isDataLoaded.personalInfo
      ) {
        personalInfoRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (
        location.hash === '#photos' &&
        photosRef.current &&
        isDataLoaded.photos
      ) {
        photosRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (
        location.hash === '#videos' &&
        videosRef.current &&
        isDataLoaded.videos
      ) {
        videosRef.current.scrollIntoView({ behavior: 'smooth' });
      } else if (
        location.hash === '#sport-background' &&
        sportBackgroundRef.current &&
        isDataLoaded.sportBackground
      ) {
        sportBackgroundRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToSection();
  }, [location, isDataLoaded]);

  const sections = [
    { id: 'personal-info', component: <PersonalInfo />, ref: personalInfoRef },
    {
      id: 'sport-background',
      component: <SportBackgroundsSection />,
      ref: sportBackgroundRef,
    },
    { id: 'photos', component: <Photos />, ref: photosRef },
    { id: 'videos', component: <Videos />, ref: videosRef },
  ];

  return (
    <SidebarLayout sidebar={<ProfileSidebar />} {...mobileProps}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: '1.375rem',
        }}
      >
        {sections.map(({ id, component, ref }) => (
          <Box key={id}>
            <Box
              id={id}
              ref={ref}
              sx={{
                display: 'block',
                position: 'relative',
                top: '-4.5rem',
                visibility: 'hidden',
              }}
            />
            {component}
          </Box>
        ))}
      </Box>
    </SidebarLayout>
  );
};

export default ProfilePage;
