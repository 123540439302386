import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { IComment } from '../../interfaces/comment.interface';
import { useGetUserInfoQuery } from '../../store/apiSlice/userApi';
import { formatDate } from '../../utils/date-time-formats.ts/formatDate';
import { useEffect, useState } from 'react';
import LikeIcon from '../Icons/LikeIcon';
import { SnackbarSeverityEnum } from '../../enums/snackbar-severity-enum';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { errorHelper } from '../../utils/helper/error-helper';

import SendIcon from '../Icons/SendIcon';
import {
  useCreateCommentMutation,
  useGetCommentRepliesQuery,
} from '../../store/apiSlice/post/commentApi';
import {
  useDislikeCommentMutation,
  useLikeCommentMutation,
} from '../../store/apiSlice/post/commentReactionApi';
import ReplyIcon from '../Icons/ReplyIcon';
import { Link } from 'react-router-dom';
import ProfileCommentMoreMenu from '../../pages/home/shared-components/posts/ProfileCommentMoreMenu';
import { selectAuthData } from '../../store/selectors/authSelector';
import { useThemeContext } from '../../theme/ThemeContextProvider';

interface ICommentItemPorops {
  comment: IComment;
  isReply?: boolean;
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
  setSnackbarSeverity: React.Dispatch<
    React.SetStateAction<SnackbarSeverityEnum>
  >;
  setCommentsCount?: React.Dispatch<React.SetStateAction<number>>;
  setPostCommentsPage: React.Dispatch<React.SetStateAction<number>>;
}

const REPLIES_LIMIT = 5;

const CommentItem: React.FC<ICommentItemPorops> = ({
  comment,
  isReply = false,
  setSnackbarOpen,
  setSnackbarMessage,
  setSnackbarSeverity,
  setPostCommentsPage,
}) => {
  const { theme } = useThemeContext();
  const [likesCount, setLikesCount] = useState<number>(comment.likesCount);
  const [repliesCount, setRepliesCount] = useState<number>(comment.likesCount);
  const [isCommentInputOpen, setIsCommentInputOpen] = useState<boolean>(false);

  const [commentRepliesArray, setCommentRepliesArray] = useState<IComment[]>(
    []
  );
  const [commentReplyPage, setCommentReplyPage] = useState<number>(1);
  const [hasMoreReplies, setHasMoreReplies] = useState<boolean>(true);

  const handleLoadMoreReplies = () => setCommentReplyPage(prev => prev + 1);

  const handleOpenCommentInput = () => setIsCommentInputOpen(prev => !prev);

  const { user: myData } = useSelector(selectAuthData);

  const { data: userData } = useGetUserInfoQuery(comment.userId);
  const repliesParams = {
    commentId: comment.id,
    page: commentReplyPage,
    limit: REPLIES_LIMIT,
  };
  const { data: repliesData } = useGetCommentRepliesQuery(repliesParams);

  useEffect(() => {
    if (repliesData) {
      setRepliesCount(repliesData.total);
    }
  }, [repliesData]);

  useEffect(() => {
    let newCommentReplies: IComment[] = [];

    newCommentReplies = repliesData?.data || [];

    if (commentReplyPage === 1) {
      setCommentRepliesArray(newCommentReplies);
    } else {
      setCommentRepliesArray(prevCommentReplies => [
        ...prevCommentReplies,
        ...newCommentReplies,
      ]);
    }

    if (repliesData) {
      const hasNext =
        repliesData.limit * (repliesData.page - 1) + repliesData.limit <
        repliesData.total;
      setHasMoreReplies(hasNext);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repliesData]);

  const [isLiked, setIsLiked] = useState<boolean>(
    isCommentLikedByUser(comment.reactions, myData?.id as string) || false
  );
  const [commentMsg, setCommentMsg] = useState<string>('');

  function isCommentLikedByUser(commentReactions: any[], userId: string) {
    return commentReactions.some(
      reaction => reaction.userId === userId && reaction.type === 'LIKE'
    );
  }

  const [likeComment, { isLoading: isLiking }] = useLikeCommentMutation();

  const handleLike = async (commentId: string) => {
    try {
      await likeComment(commentId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev + 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Comment liked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to like the comment: ', error);
    }
  };
  const [dislikeComment, { isLoading: isDisliking }] =
    useDislikeCommentMutation();

  const handleDislike = async (commentId: string) => {
    try {
      await dislikeComment(commentId).unwrap();

      setIsLiked(prev => !prev);
      setLikesCount(prev => prev - 1);
      setSnackbarOpen(true);
      setSnackbarMessage('Comment disliked!');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Dislike failed: ', error);
    }
  };

  const [commentPost, { isLoading: isCommenting }] = useCreateCommentMutation();

  const handleComment = async (postId: string, parentId?: string) => {
    if (commentMsg) {
      try {
        const newCommentData: {
          postId: string;
          parentId?: string;
          message: string;
        } = {
          postId,
          message: commentMsg,
        };
        if (parentId) newCommentData.parentId = parentId;
        await commentPost(newCommentData).unwrap();
        setRepliesCount(prev => prev + 1);
        setSnackbarOpen(true);
        setSnackbarMessage('Post commented!');
        setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
        setCommentMsg('');
        setCommentReplyPage(1);
        handleOpenCommentInput();
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(errorHelper(error));
        setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
        console.error('Comment failed: ', error);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: '1rem 0',
        borderRadius: '0.25rem',
        paddingRight: isReply ? 0 : 1,
      }}
    >
      <Grid container gap={'1rem'} flexWrap={'nowrap'}>
        <Grid item component={Link} to={`/${userData?.id}`}>
          <Avatar
            alt="Profile"
            src={
              userData?.optimizedUserAvatarBaseUrl
                ? `data:image/jpeg;base64,${userData.optimizedUserAvatarBaseUrl}`
                : ''
            }
            sx={{ width: 50, height: 50 }}
          />
        </Grid>
        <Grid item direction={'column'}>
          <Stack
            direction={'row'}
            gap={'6px'}
            component={Link}
            to={`/${userData?.id}`}
          >
            <Typography
              variant="body2"
              color={'black'}
              fontSize={'14px'}
              fontWeight={700}
              p={0}
            >
              {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
            </Typography>
            <Typography variant="body2" color={'text.darker'} fontSize={'12px'}>
              {formatDate(new Date(comment.createdAt))}
            </Typography>
          </Stack>
          <Grid item flex={1}>
            <Typography
              variant="body2"
              fontSize={'14px'}
              color={'text.primary'}
              textAlign={'justify'}
            >
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  a: ({ href, children, ...props }) => {
                    if (!href) return <>{children}</>;

                    const isExternalLink = /^https?:\/\//.test(href);
                    const isPostLink = href.startsWith('/post/');

                    return isExternalLink ? (
                      <a
                        href={href}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...props}
                        style={{ textDecoration: 'none' }}
                      >
                        <Typography
                          variant="body3"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {children}
                        </Typography>
                      </a>
                    ) : (
                      <Link to={isPostLink ? href : `/${href}`} {...props}>
                        <Typography
                          variant="body3"
                          sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 700,
                          }}
                        >
                          {children}
                        </Typography>
                      </Link>
                    );
                  },
                }}
              >
                {comment.message}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>

        <Stack
          direction={'row'}
          spacing={1}
          ml={'auto'}
          alignItems={'center'}
          alignSelf={'start'}
        >
          <IconButton
            sx={{
              width: 24,
              height: 24,
              minHeight: 24,
              minWidth: 24,
              p: 1,
              fontSize: 12,
            }}
            onClick={() => {
              isLiked ? handleDislike(comment.id) : handleLike(comment.id);
            }}
            disabled={isLiking || isDisliking}
          >
            <LikeIcon fillNone={!isLiked} isRed={isLiked} /> {likesCount}
          </IconButton>
          <ProfileCommentMoreMenu
            comment={comment}
            setSnackbarMessage={setSnackbarMessage}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarSeverity={setSnackbarSeverity}
            setPostCommentsPage={setPostCommentsPage}
          />
        </Stack>
      </Grid>

      <Stack
        justifyContent={'flex-start'}
        direction={'row'}
        alignItems={'center'}
      >
        {!isReply && (
          <IconButton
            onClick={handleOpenCommentInput}
            sx={{
              width: 'fit-content',
              height: 18,
              minHeight: 18,
              minWidth: 40,
              p: 1,
              fontSize: 12,
              color: 'text.secondary',
              borderRadius: '1px',
              gap: '6px',
              mt: '6.5px',
            }}
          >
            <ReplyIcon />
            Reply
            {repliesCount > 0 &&
              `  •  ${repliesCount} repl${repliesCount > 1 ? 'ies' : 'y'}`}
          </IconButton>
        )}
      </Stack>

      {commentRepliesArray.length > 0 && (
        <Box>
          <Divider sx={{ mt: '16px', mb: '16px' }} />
          <Stack component={'ul'} spacing={1} width={'95%'} ml={'auto'}>
            {commentRepliesArray.map(reply => (
              <CommentItem
                isReply
                key={reply.id}
                comment={reply}
                setSnackbarMessage={setSnackbarMessage}
                setSnackbarOpen={setSnackbarOpen}
                setSnackbarSeverity={setSnackbarSeverity}
                setPostCommentsPage={setPostCommentsPage}
              />
            ))}
          </Stack>
          {hasMoreReplies && (
            <IconButton
              onClick={handleLoadMoreReplies}
              sx={{
                height: 17,
                minHeight: 17,
                minWidth: 200,
                fontSize: 14,
                fontWeight: 700,
                borderRadius: 12,
                p: 0,
                '&:hover': { bgcolor: 'transparent' },
                color: 'text.primary',
              }}
            >
              Show more
            </IconButton>
          )}
        </Box>
      )}

      {!isReply && isCommentInputOpen && (
        <>
          <Divider
            sx={{ width: '95%', marginLeft: 'auto', mt: '16px', mb: '16px' }}
          />
          <Grid container columnGap={2} width={'95%'} ml={'auto'}>
            <Grid item height="fit-content">
              <Avatar
                alt="Profile"
                src={
                  myData && myData?.optimizedUserAvatarBaseUrl
                    ? `data:image/jpeg;base64,${myData.optimizedUserAvatarBaseUrl}`
                    : ''
                }
                sx={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item flex={1} component={'form'}>
              <TextField
                value={commentMsg}
                onChange={e => {
                  setCommentMsg(e.target.value);
                }}
                id="input-with-icon-textfield"
                fullWidth
                sx={{
                  height: 40,
                  '& .MuiInputBase-root': {
                    backgroundColor: 'background.light',
                    borderRadius: '4px',
                  },
                  '& .MuiInputBase-input': {
                    height: '40px !important',
                    backgroundColor: 'background.light',
                    borderRadius: '4px',
                  },
                  '& .MuiSvgIcon-root': {
                    backgroundColor: 'unset',
                  },
                }}
                placeholder="Reply..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        aria-label="send reply button"
                        sx={{
                          p: '0px',
                          width: 25,
                          minWidth: '25px',
                        }}
                        disabled={isCommenting}
                        onClick={e => {
                          e.preventDefault();
                          handleComment(comment.postId, comment.id);
                        }}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CommentItem;
