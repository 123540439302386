import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import dayjs from 'dayjs';
import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { useThemeContext } from '../../../../../theme/ThemeContextProvider';
import DateInput from '../../../../MUIComponents/DateInput';
import Input from '../../../../MUIComponents/Input';
import { SportBackgroundFormInputs } from '../SportBackgroundItem/SportBackgroundItem';
import Sport from './Sport';

interface CareerTimelineInputsProps {
  control: Control<SportBackgroundFormInputs, any>;
  errors: FieldErrors<SportBackgroundFormInputs>;
  onFieldChange: (field: keyof SportBackgroundFormInputs, value: any) => void;
  disabled: boolean;
}

const CareerTimelineInputs = ({
  control,
  errors,
  onFieldChange,
  disabled,
}: CareerTimelineInputsProps) => {
  const isActiveSport = useWatch({
    control,
    name: 'isActiveSport',
  });

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="body4" fontWeight={700} padding={'0 2px'}>
          Your career timeline:
        </Typography>
        <Grid
          container
          sx={theme => ({
            display: 'flex',
            gap: '1.375rem',
            [theme.breakpoints.down('md')]: {
              gridColumn: 'span 2',
              display: 'block',
              gap: '1rem',
            },
          })}
        >
          <Grid item width={184}>
            <DateInput<SportBackgroundFormInputs>
              control={control}
              name="startCareerDate"
              error={errors.startCareerDate as FieldError}
              helperText={errors.startCareerDate?.message || ''}
              placeholder="Your start date"
              onChange={value => onFieldChange('startCareerDate', dayjs(value))}
              disabled={disabled}
            />
          </Grid>
          <Grid item width={184}>
            {!isActiveSport && (
              <DateInput<SportBackgroundFormInputs>
                control={control}
                name="endCareerDate"
                error={errors.endCareerDate as FieldError}
                helperText={errors.endCareerDate?.message || ''}
                placeholder="Your end date"
                onChange={value => onFieldChange('endCareerDate', dayjs(value))}
                disabled={disabled}
              />
            )}
          </Grid>
        </Grid>
        <Box pt="36px">
          <Controller
            name="isActiveSport"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onChange={(e, checked) => {
                      field.onChange(e);
                      onFieldChange('isActiveSport', checked);
                    }}
                    sx={{
                      padding: 0,
                      margin: 0,
                    }}
                  />
                }
                label={
                  <Typography
                    variant={'caption'}
                    sx={{ fontWeight: 400, fontSize: '14px' }}
                  >
                    My career in athletics is ongoing
                  </Typography>
                }
                sx={{
                  padding: 0,
                  margin: 0,
                  gap: '8px',
                  width: '100%',
                  alignItems: 'center',
                }}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
};

interface SportFormSectionProps {
  register: UseFormRegister<SportBackgroundFormInputs>;
  control: Control<SportBackgroundFormInputs, any>;
  setFormValue: UseFormSetValue<SportBackgroundFormInputs>;
  errors: FieldErrors<SportBackgroundFormInputs>;
  onFieldChange: (field: keyof SportBackgroundFormInputs, value: any) => void;
  disabled: boolean;
  isOtherSport: boolean;
}

const SportFormSection = ({
  register,
  control,
  setFormValue,
  errors,
  onFieldChange,
  disabled,
  isOtherSport,
}: SportFormSectionProps) => {
  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {isOtherSport && (
        <Grid container gap="1.375rem">
          <Grid item container flexDirection="column" flex={1} rowGap="16px">
            <Sport
              control={control}
              setFormValue={setFormValue}
              formStateErrors={errors}
              disabled={disabled}
              handleFieldChange={onFieldChange}
            />
            <Box>
              <Controller
                name="isIndividualSport"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onChange={(e, checked) => {
                          field.onChange(e);
                          onFieldChange('isIndividualSport', checked);
                        }}
                        sx={{
                          padding: 0,
                          margin: 0,
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant={'caption'}
                        sx={{ fontWeight: 400, fontSize: '14px' }}
                      >
                        Individual sport
                      </Typography>
                    }
                    sx={{
                      padding: 0,
                      margin: 0,
                      gap: '8px',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item>
            <CareerTimelineInputs
              control={control}
              errors={errors}
              onFieldChange={onFieldChange}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      )}

      {!isOtherSport && (
        <CareerTimelineInputs
          control={control}
          errors={errors}
          onFieldChange={onFieldChange}
          disabled={disabled}
        />
      )}

      <Box
        display="flex"
        gridColumn="span 2"
        rowGap={mobileView ? '1.75rem' : '36px'}
        columnGap="16px"
        mt="36px"
      >
        <Input
          caption="Describe your sport background:"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          placeholder="Few words about your sports experience"
          register={register('description', {
            onChange: e => onFieldChange('description', e.target.value),
          })}
          error={!!errors.description}
          helperText={errors.description?.message || ''}
          disabled={disabled}
          containerFlex="1"
        />
      </Box>
    </>
  );
};

export default SportFormSection;
