import * as React from 'react';
import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { iconButtonStyle } from '../Header';
import { Link } from 'react-router-dom';
import { CookieEnum } from '../../../enums/cookie-enum';
import { Analytics, Https, Person, WorkHistory } from '@mui/icons-material';
import SettingsIcon from '../../Icons/SettingsIcon';
import LogoutIcon from '../../Icons/LogoutIcon';
import InviteFriendIcon from '../../Icons/InviteFriendIcon';
import { useDispatch, useSelector } from 'react-redux';
import userApi from '../../../store/apiSlice/userApi';
import Cookies from 'js-cookie';
import authApi from '../../../store/apiSlice/auth/authApi';
import { selectAuthData } from '../../../store/selectors/authSelector';
import { UserRoleEnum } from '../../../enums/user-data-field-enum';
import { useGetQuizByUserIdQuery } from '../../../store/apiSlice/quizApi';

interface MenuItemType {
  text: string;
  icon: React.ReactNode;
  link?: string;
  onClick?: () => void;
  divider?: boolean;
}

const AccountMenu = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const authData = useSelector(selectAuthData);
  const role = authData?.user?.role || Cookies.get(CookieEnum.ROLE);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: quiz, isLoading: isQuizLoading } = useGetQuizByUserIdQuery(
    authData?.user?.id ?? '',
    {
      skip: !role || role !== UserRoleEnum.USER || !authData?.user?.id,
    }
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Cookies.remove(CookieEnum.ACCESS_TOKEN);
    Cookies.remove(CookieEnum.REFRESH_TOKEN);
    Cookies.remove(CookieEnum.USER);
    dispatch(userApi.util.resetApiState());
    dispatch(authApi.util.resetApiState());
  };

  const userMenuItems: MenuItemType[] = [
    {
      text: 'Edit Profile',
      icon: <Person fontSize="small" />,
      link: '/profile',
      divider: true,
    },
    // feature for future
    {
      text: 'Settings & privacy',
      icon: <SettingsIcon />,
      link: '/account-settings',
      divider: true,
    },
    {
      text: 'Invite friends',
      icon: <InviteFriendIcon />,
      link: '/invitations',
      divider: true,
    },
    // TODO FA-667 - Hide career development
    // {
    //   text: 'Career development',
    //   icon: <WorkHistory fontSize="small" />,
    //   link:
    //     quiz && quiz.hasOwnProperty('id')
    //       ? '/career-development'
    //       : '/personalized-career-modal',
    //   divider: true,
    // },
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      onClick: handleLogout,
    },
  ];

  const companyMenuItems: MenuItemType[] = [
    {
      text: 'Edit Profile',
      icon: <Person fontSize="small" />,
      link: '/profile',
      divider: true,
    },
    // feature for future
    {
      text: 'Settings & privacy',
      icon: <SettingsIcon />,
      link: '/account-settings',
      divider: true,
    },
    {
      text: 'Analytics',
      icon: <Analytics />,
      link: '/analytics',
      divider: true,
    },
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      onClick: handleLogout,
    },
  ];

  const agentMenuItems: MenuItemType[] = [
    {
      text: 'Profile settings',
      icon: <Person fontSize="small" />,
      link: '/profile-settings',
      divider: true,
    },
    {
      text: 'Update password',
      icon: <Https fontSize="small" />,
      link: '/forgot-password',
      divider: true,
    },
    {
      text: 'Logout',
      icon: <LogoutIcon />,
      onClick: handleLogout,
    },
  ];

  const getFitMenuItems = useMemo(() => {
    switch (role) {
      case UserRoleEnum.USER:
        return userMenuItems;
      case UserRoleEnum.COMPANY:
        return companyMenuItems;
      case UserRoleEnum.AGENT:
        return agentMenuItems;
      default:
        return [];
    }
  }, [role, quiz, isQuizLoading]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <Box
            sx={{
              ...iconButtonStyle,
              width: '30px',
              height: '30px',
            }}
          >
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              {children}
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        sx={{
          p: 0,
          '& .MuiList-root.MuiList-padding.MuiMenu-list': { padding: 0 },
          '& .MuiDivider-root.MuiDivider-fullWidth': {
            marginTop: '0px',
            marginBottom: '0px',
          },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {getFitMenuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={item.onClick || handleClose}
            sx={{ padding: '0px 0px' }}
          >
            <Box width={'100%'}>
              <Box
                component={item.link ? Link : 'div'}
                display={'flex'}
                alignItems={'center'}
                color={'text.primary'}
                to={item.link}
                fontSize={'14px'}
                p="12px 13.5px"
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {item.text}
              </Box>
              {item.divider && <Divider />}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AccountMenu;
