import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Logo from '../Logo/Logo';
import CustomButton from '../MUIComponents/CustomButton';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
} from '../../enums/button-type-enum';
import CommentIcon from '../Icons/CommentIcon';
import { Link } from 'react-router-dom';
import AccountMenu from './AccountMenu/AccountMenu';

import ArrowDownIcon from '../Icons/ArrowDownIcon';
import NavigationMobileMenu from './NavigationMobileMenu/NavigationMobileMenu';
import { HeaderTypeEnum } from '../../enums/header-type';
import { Typography } from '@mui/material';
import NotificationsMenu from './NotificationsMenu/NotificationsMenu';
import React, { useMemo } from 'react';
import {
  useGetMyProfileQuery,
  useGetUserCompanyInfoQuery,
} from '../../store/apiSlice/userApi';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { CookieEnum } from '../../enums/cookie-enum';
import { selectAuthData } from '../../store/selectors/authSelector';
import { setAuthData } from '../../store/apiSlice/auth/authSlice';
import {
  setChatState,
  setSelectedChat,
} from '../../store/apiSlice/chat/chatSlice';
import { ChatStateEnum } from '../../enums/chat-enum';
import SearchBar from './SearchBar/SearchBar';
import { UserRoleEnum } from '../../enums/user-data-field-enum';
import JobsIcon from '../Icons/pageIcons/JobsIcon';
import MyFeedsIcon from '../Icons/pageIcons/MyFeedsIcon';
import ConnectionsIcon from '../Icons/pageIcons/ConnectionsIcon';
import FollowersIcon from '../Icons/pageIcons/FollowersIcon';
import CandidatesIcon from '../Icons/pageIcons/CandidatesIcon';
import ChatsIcon from '../Icons/pageIcons/ChatsIcon';

const userPages = [
  {
    label: 'My Feed',
    to: '/',
    state: { scrollTop: true },
    icon: <MyFeedsIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
  },
  {
    label: 'Connections',
    to: '/connections',
    icon: <ConnectionsIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
  },
  {
    label: 'Job Board',
    to: '/job-posts',
    icon: <JobsIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
  },
];

const companyPages = [
  {
    label: 'Followers',
    to: '/',
    icon: <FollowersIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
  },
  {
    label: 'Find employee',
    to: '/candidates',
    icon: <CandidatesIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
  },
];

export const iconButtonStyle = {
  width: '2rem',
  height: '2rem',
  backgroundColor: 'background.light',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

interface IHeaderProps {
  type?: HeaderTypeEnum;
}

interface Page {
  label: string;
  to: string;
  state?: any;
}

const Header = ({ type = HeaderTypeEnum.BASE }: IHeaderProps) => {
  const authData = useSelector(selectAuthData);
  const role = authData?.user?.role || Cookies.get(CookieEnum.ROLE);
  const dispatch = useDispatch();

  const getHeaderPages = useMemo<Page[]>(() => {
    switch (role) {
      case UserRoleEnum.COMPANY:
        return companyPages;
      case UserRoleEnum.USER:
        return userPages;
      default:
        return [];
    }
  }, [role]);

  const headerTabs = useMemo(() => {
    switch (type) {
      case HeaderTypeEnum.VERIFY_ACCOUNT: {
        return <Typography variant="body3">Verify account</Typography>;
      }
      case HeaderTypeEnum.COMPANY_REGISTRATION: {
        return <Typography variant="body3">Company registration</Typography>;
      }
      default: {
        return getHeaderPages.map((page: Page) => (
          <Link to={page.to} state={page.state} key={page.label}>
            <CustomButton
              fontSize="0.875rem"
              variantType={ButtonTypeEnum.MENU}
              iconPosition={ButtonIconPositionEnum.NONE}
            >
              {page.label}
            </CustomButton>
          </Link>
        ));
      }
    }
  }, [type, getHeaderPages]);

  let avatarUrl = '';
  const { data: companyInfo } = useGetUserCompanyInfoQuery(undefined, {
    skip: role !== UserRoleEnum.COMPANY,
  });
  const { data: profileInfo } = useGetMyProfileQuery(undefined, {
    skip: role !== UserRoleEnum.USER && role !== UserRoleEnum.AGENT,
  });
  if (role === UserRoleEnum.COMPANY) {
    avatarUrl = companyInfo?.avatar?.fileUrl || '';
    dispatch(setAuthData({ user: companyInfo }));
  } else {
    avatarUrl = profileInfo?.avatar?.fileUrl || '';
    dispatch(setAuthData({ user: profileInfo }));
  }

  const navigateToChats = (type: ChatStateEnum) => {
    dispatch(setChatState(type));
    dispatch(setSelectedChat(undefined));
  };

  const userMobilePages = [
    {
      label: 'Chats',
      to: '/chat',
      navigateFunction: () => navigateToChats(ChatStateEnum.ALL_CHATS),
      icon: <ChatsIcon sx={{ width: 28, height: 28, fill: 'none' }} />,
    },
  ];

  return (
    <AppBar
      position="sticky"
      sx={{
        bgcolor: 'background.white',
        boxShadow: 0,
        height: '4.5rem',
        justifyContent: 'center',
        borderBottom: '1px solid',
        borderColor: 'background.light',
      }}
    >
      <Container
        maxWidth="xl"
        sx={{ px: { md: '12px', lg: '24px', xl: '60px' } }}
      >
        <Toolbar
          disableGutters
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1.375rem',
          }}
        >
          <Box mr={'12px'} display={{ xs: 'block', md: 'none' }}>
            <NavigationMobileMenu
              pages={[...getHeaderPages, ...userMobilePages]}
              avatarUrl={avatarUrl}
            />
          </Box>

          <Box
            component={Link}
            to={'/'}
            sx={{
              maxWidth: '9rem',
              marginRight: { xs: 'auto', lg: '0px' },
            }}
          >
            <Logo />
          </Box>

          <VerticalDivider />

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', md: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '1.375rem',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '1.75rem',
              }}
            >
              {headerTabs}
            </Box>

            <SearchBar />
          </Box>

          <VerticalDivider />

          {type === HeaderTypeEnum.BASE && (
            <>
              <Box
                sx={{
                  display: { xs: 'none', lg: 'flex' },
                  alignItems: 'center',
                  gap: { md: '0.25rem', lg: '0.5rem', xl: '0.75rem' },
                }}
              >
                <Box sx={{ ...iconButtonStyle }}>
                  <NotificationsMenu />
                </Box>
                <Link
                  onClick={() => navigateToChats(ChatStateEnum.NEW_PERSONAL)}
                  to="/chat"
                >
                  <Box sx={{ ...iconButtonStyle }}>
                    <CommentIcon />
                  </Box>
                </Link>
              </Box>
              <VerticalDivider />
            </>
          )}

          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              alignItems: 'center',
              gap: '1.375rem',
              flexGrow: 0,
            }}
          >
            <Link to={'/me'}>
              <CustomButton
                fontSize={'0.875rem'}
                variantType={ButtonTypeEnum.MENU}
                iconPosition={ButtonIconPositionEnum.LEFT}
                icon={
                  <Avatar
                    sx={{ mr: '0.75rem' }}
                    alt="Profile"
                    src={avatarUrl}
                  />
                }
                sx={{
                  mr: '1.375rem',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                My Profile
              </CustomButton>
            </Link>
            <Box display={{ xs: 'none', lg: 'block' }}>
              <AccountMenu>
                <ArrowDownIcon />
              </AccountMenu>
            </Box>
          </Box>
          <Box display={{ xs: 'block', lg: 'none' }}>
            <AccountMenu>
              <Avatar sx={{ mr: 1 }} alt="Profile" src={avatarUrl} />
            </AccountMenu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default Header;

const VerticalDivider: React.FC = () => (
  <Divider
    orientation="vertical"
    flexItem
    sx={{
      display: { xs: 'none', lg: 'inline-block' },
      alignSelf: 'center',
      height: '2rem',
    }}
  />
);
