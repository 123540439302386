import { SxProps } from '@mui/material';
import { EditorProps } from 'draft-js';
import {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useGetPaginatedUsersQuery } from '../../../../store/apiSlice/searchApi';
import MentionsTextField, {
  EditorRef,
  Suggestion,
} from '../MentionsTextField/MentionsTextField';
import { EMPTY_AVATAR } from '../../../../constants/empty-avatar';

interface MentionsTextFieldWrapperProps
  extends Omit<EditorProps, 'plugins' | 'editorState' | 'onChange'> {
  sx?: SxProps;
  height?: string | number;
  minHeight?: string | number;
  onInputChange?: (value: string) => void;
  setMentions?: (mentions: Suggestion[]) => void;
  placeholder?: string;
  name?: string;
  label?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn<any>;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
}

const MentionsTextFieldWrapper = forwardRef<
  EditorRef,
  MentionsTextFieldWrapperProps
>(
  (
    {
      sx,
      height,
      minHeight,
      keyBindingFn,
      onInputChange,
      setMentions,
      handleKeyCommand,
      placeholder,
      name,
      label,
      maxLength,
      register,
      error,
      helperText,
      disabled,
    }: MentionsTextFieldWrapperProps,
    ref: ForwardedRef<EditorRef>
  ) => {
    const [search, setSearch] = useState<string>('');
    // to do: add loader to the MentionSuggestions in MentionsTextField, see popoverContainer prop
    // eslint-disable-next-line
    const { data: users, isLoading: isLoadingUsers } =
      useGetPaginatedUsersQuery({
        page: 1,
        limit: 10,
        search,
        sort: 'asc',
      });

    const suggestions = useMemo(
      () =>
        users?.data
          .filter(user => user.firstName || user.lastName)
          .map(user => ({
            name: `${user.firstName} ${user.lastName}`,
            link: user.id,
            avatar: user.avatar?.fileUrl || EMPTY_AVATAR,
            userId: user.id,
          })),
      [users]
    );

    const handleSearchChange = useCallback((event: { value: string }) => {
      setSearch(event.value.trim());
    }, []);

    return (
      <MentionsTextField
        sx={sx}
        ref={ref}
        height={height}
        minHeight={minHeight}
        suggestions={suggestions || []}
        setMentions={setMentions}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
        onInputChange={onInputChange}
        onSearchChange={handleSearchChange}
        name={name}
        label={label}
        maxLength={maxLength}
        placeholder={placeholder}
        register={register}
        error={error}
        helperText={helperText}
        readOnly={disabled}
      />
    );
  }
);

export default memo(MentionsTextFieldWrapper);
