import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface ConnectionIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const ConnectionsIcon = ({
  iconColor,
  active,
  ...svgProps
}: ConnectionIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <circle cx="14" cy="9" r="4" stroke={color} strokeWidth="1.5" />
      <path
        d="M7 23C7 19 10 16 14 16C18 16 21 19 21 23"
        stroke={color}
        strokeWidth="1.5"
      />
      <circle cx="6" cy="11" r="3" stroke={color} strokeWidth="1.5" />
      <circle cx="22" cy="11" r="3" stroke={color} strokeWidth="1.5" />
      <path d="M3 23C3 20 5 18 7 17" stroke={color} strokeWidth="1.5" />
      <path d="M25 23C25 20 23 18 21 17" stroke={color} strokeWidth="1.5" />
    </SvgIcon>
  );
};

export default ConnectionsIcon;
