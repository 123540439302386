import {
  Container,
  Grid,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useMemo, useState } from 'react';
import { useCurrentPageUserId } from '../../../../hooks/useCurrentPageUserId';
import { a11yProps } from '../../../../utils/a11yProps';
import ProfileScoreHeader from '../../shared-components/profile-score/ProfileScoreHeader';
import {
  AboutTab,
  AchievementsTab,
  ExperienceTab,
  MyJobApplicationsTab,
  PhotosTab,
  PostsTab,
  VideosTab,
} from '../tabs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGetMyProfileScoreQuery } from '../../../../store/apiSlice/userApi';
import ProfileScoreList from '../../shared-components/profile-score/ProfileScoreList';
import { useGetAllMySuggestionsQuery } from '../../../../store/apiSlice/friendApi';
import RecommendationFriend from '../../shared-components/recommendation-friend/RecommendationFriend';
import { useSelector } from 'react-redux';
import { selectAuthData } from '../../../../store/selectors/authSelector';
import Cookies from 'js-cookie';
import { CookieEnum } from '../../../../enums/cookie-enum';
import SkeletonList from '../../../../components/MUIComponents/SkeletonList';
import NotAllowedPageContent from '../../shared-components/not-allowed-page-content/NotAllowedPageContent';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

interface ProfileTabsProps {
  hasAccess?: boolean;
  isBlocked?: boolean;
  isAccessLoading?: boolean;
}

const ProfileTabs = (props: ProfileTabsProps) => {
  const { hasAccess, isBlocked, isAccessLoading } = props;
  const theme = useTheme();
  const { isOwnPage } = useCurrentPageUserId();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(0);

  const authData = useSelector(selectAuthData);
  const isCompany =
    authData?.user?.role === 'COMPANY' ||
    Cookies.get(CookieEnum.ROLE) === 'COMPANY';

  const { data: myProfileScoreData, isLoading: myProfileScoreIsLoading } =
    useGetMyProfileScoreQuery();

  const percentage = myProfileScoreData
    ? Object.values(myProfileScoreData).filter(Boolean).length * 20
    : 0;

  const {
    data: suggestionsData,
    error: suggestionsError,
    isLoading: suggestionsLoading,
  } = useGetAllMySuggestionsQuery();

  useEffect(() => {
    const tabFromUrl = new URLSearchParams(location.search).get('tab');
    if (tabFromUrl && !isNaN(+tabFromUrl)) {
      setSelectedTab(+tabFromUrl);
    }
  }, [location.search]);

  const tabs = useMemo(() => {
    const LoadingWrapper = ({
      isLoading,
      children,
    }: {
      isLoading?: boolean;
      children: React.ReactNode;
    }) => {
      if (isLoading) {
        return <Skeleton variant="rectangular" width="100%" height="100%" />;
      }
      return <>{children}</>;
    };

    const baseTabs = [
      {
        name: 'ABOUT',
        container: (
          <LoadingWrapper isLoading={isAccessLoading}>
            {!isBlocked ? (
              <AboutTab />
            ) : (
              <NotAllowedPageContent
                title={'You are not allowed to view this page.'}
              />
            )}
          </LoadingWrapper>
        ),
      },
      {
        name: 'EXPERIENCE',
        container: (
          <LoadingWrapper isLoading={isAccessLoading}>
            {!isBlocked ? (
              <ExperienceTab />
            ) : (
              <NotAllowedPageContent
                title={'You are not allowed to view this page.'}
              />
            )}
          </LoadingWrapper>
        ),
      },
      // TODO FA-648 Hide achievements for now
      // {
      //   name: 'ACHIEVEMENTS',
      //   container: (
      //     <LoadingWrapper isLoading={isAccessLoading}>
      //       {!isBlocked && hasAccess ? (
      //         <AchievementsTab />
      //       ) : (
      //         <NotAllowedPageContent
      //           title={'You are not allowed to view this page.'}
      //         />
      //       )}
      //     </LoadingWrapper>
      //   ),
      // },
      {
        name: 'PHOTOS',
        container: (
          <LoadingWrapper isLoading={isAccessLoading}>
            {!isBlocked && hasAccess ? (
              <PhotosTab />
            ) : (
              <NotAllowedPageContent
                title={'You are not allowed to view this page.'}
              />
            )}
          </LoadingWrapper>
        ),
      },
      {
        name: 'VIDEOS',
        container: (
          <LoadingWrapper isLoading={isAccessLoading}>
            {!isBlocked && hasAccess ? (
              <VideosTab />
            ) : (
              <NotAllowedPageContent
                title={'You are not allowed to view this page.'}
              />
            )}
          </LoadingWrapper>
        ),
      },
    ];

    const postsTab = {
      name: isOwnPage ? 'MY POSTS' : 'POSTS',
      container: (
        <LoadingWrapper isLoading={isAccessLoading}>
          {!isBlocked && hasAccess ? (
            <PostsTab />
          ) : (
            <NotAllowedPageContent
              title={'You are not allowed to view this page.'}
            />
          )}
        </LoadingWrapper>
      ),
    };

    const myPage = [
      postsTab,
      ...baseTabs,
      { name: 'MY APPLICATIONS', container: <MyJobApplicationsTab /> },
    ];
    const userPage = [postsTab, ...baseTabs];

    return isOwnPage ? myPage : userPage;
  }, [isOwnPage, hasAccess, isBlocked, isAccessLoading]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    navigate(`?tab=${newValue}`);
  };

  return (
    <Box sx={theme => ({ width: '100%', color: theme.palette.text.primary })}>
      <Box
        sx={{
          padding: { xs: '16px 0 16px 16px', lg: '16px 0', xl: '22px 0' },
          width: '100%',
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="profile tabs"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
          sx={{
            backgroundColor: theme.palette.background.white,
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((item, index) => (
            <Tab
              key={item.name}
              {...a11yProps(index)}
              label={item.name}
              sx={{
                flex: { xs: 'none', lg: 1 },
                borderRadius: '1px',
                color: theme.palette.text.primary,
                fontSize: '0.625rem',
                fontWeight: 800,
                textWrap: 'nowrap',
                letterSpacing: '0.025rem',
                padding: '1rem 1.375rem',
                minWidth: 120,
                '&.Mui-selected': {
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.background.white,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      {tabs.map((item, index) => (
        <TabPanel key={item.name} value={selectedTab} index={index}>
          <Container
            disableGutters
            className="profile-tab-container"
            maxWidth={false}
          >
            <Grid container columnGap={{ lg: '16px', xl: '22px' }}>
              {/* minWidth is must have rule to shrink the container 
            properly if the content of the nested span are too large */}
              <Grid
                item
                className="profile-tab-content"
                flex={1}
                width="100%"
                minWidth="1px" // greeter then 0 for proper placement on mobile devices
                sx={mobileView ? { order: 1 } : {}}
              >
                {item.container}
              </Grid>
              <Grid
                item
                className="profile-tab-rightbar"
                width="100%"
                maxWidth={{
                  lg: isCompany ? 'auto' : 236,
                  xl: isCompany ? 'auto' : 314,
                }}
                height="fit-content"
                sx={
                  mobileView
                    ? { order: 0, width: '100%', marginBottom: '20px' }
                    : {}
                }
              >
                {isOwnPage && (
                  <Stack
                    sx={{
                      bgcolor: theme.palette.background.white,
                    }}
                  >
                    {myProfileScoreIsLoading ? (
                      <Skeleton
                        variant="rounded"
                        sx={{
                          margin: '22px 16px',
                          width: 'calc(100% - 32px)',
                          minHeight: {
                            xs: '130px',
                            lg: '100px',
                          },
                        }}
                      />
                    ) : (
                      <ProfileScoreHeader percentage={percentage} />
                    )}
                    {!myProfileScoreData ? (
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'16px'}
                        marginTop={'16px'}
                      >
                        <SkeletonList
                          count={2}
                          sx={{
                            margin: '22px 16px',
                            width: 'calc(100% - 32px)',
                            minHeight: {
                              xs: '300px',
                              lg: '350px',
                            },
                          }}
                        />
                      </Box>
                    ) : (
                      <ProfileScoreList profileScore={myProfileScoreData} />
                    )}
                  </Stack>
                )}
                {!isOwnPage && !isCompany && (
                  <>
                    {suggestionsLoading ? (
                      <Stack
                        sx={{
                          bgcolor: theme.palette.background.white,
                          padding: '22px 16px',
                        }}
                      >
                        <SkeletonList
                          count={1}
                          sx={{
                            width: '100%',
                            minHeight: {
                              xs: '18.75rem',
                              lg: '18.75rem',
                            },
                          }}
                        />
                      </Stack>
                    ) : (
                      suggestionsData?.suggestions && (
                        <RecommendationFriend
                          suggestions={suggestionsData.suggestions}
                        />
                      )
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Container>
        </TabPanel>
      ))}
    </Box>
  );
};

export default ProfileTabs;
