import {
  Avatar,
  Box,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApprovedProfileIcon from '../../../../../components/Icons/ApprovedProfileIcon';
import LocationIcon from '../../../../../components/Icons/LocationIcon';
import { ChatStateEnum } from '../../../../../enums/chat-enum';
import { CookieEnum } from '../../../../../enums/cookie-enum';
import { useCurrentPageUserId } from '../../../../../hooks/useCurrentPageUserId';
import { useModalManager } from '../../../../../hooks/useModalManager';
import {
  useCreateChatMutation,
  useGetAllMyChatsQuery,
} from '../../../../../store/apiSlice/chat/chatApi';
import {
  setChatState,
  setSelectedChat,
} from '../../../../../store/apiSlice/chat/chatSlice';
import { useGetUserInformationQuery } from '../../../../../store/apiSlice/userApi';
import { selectAuthData } from '../../../../../store/selectors/authSelector';
import { colors } from '../../../../../theme/theme';
import { RequestIntroductionModal } from '../modals';
import IntroductionCheckModal from '../../../shared-components/modals/IntroductionCheckModal';
import CustomButton from '../../../../../components/MUIComponents/CustomButton';
import { ButtonTypeEnum } from '../../../../../enums/button-type-enum';
import {
  useCheckIfMyFriendInvitationExistQuery,
  useDeleteInviteToFriendMutation,
  useInviteToFriendMutation,
} from '../../../../../store/apiSlice/friendInvitationApi';
import { SnackbarSeverityEnum } from '../../../../../enums/snackbar-severity-enum';
import { errorHelper } from '../../../../../utils/helper/error-helper';
import SnackbarCustom from '../../../../../components/MUIComponents/SnackbarCustom';
import friendApi, {
  useBlockMyFriendMutation,
  useUnblockMyFriendMutation,
} from '../../../../../store/apiSlice/friendApi';
import { UserRoleEnum } from '../../../../../enums/user-data-field-enum';
import { useGetUserSportExperienceByIdQuery } from '../../../../../store/apiSlice/careerApi';
import { ISportExperienceResponse } from '../../../../../interfaces/career.interface';
import ExperienceBox from './ExperienceBox';

interface IProfileCard {
  hasAccess?: boolean;
  isBlocked?: boolean;
  hasMutualFriends?: boolean;
}
const ProfileCard = (props: IProfileCard) => {
  const { hasAccess, isBlocked, hasMutualFriends } = props;

  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<SnackbarSeverityEnum>(SnackbarSeverityEnum.ERROR);

  const { userId, isOwnPage } = useCurrentPageUserId();
  const authData = useSelector(selectAuthData);
  const role = authData?.user?.role || Cookies.get(CookieEnum.ROLE);
  const { currentModal, toggleModal } = useModalManager();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const {
    data: checkIfMyFriendInvitationExist,
    isLoading: isCheckIfMyFriendInvitationExistLoading,
  } = useCheckIfMyFriendInvitationExistQuery(userId || '', {
    skip: !userId || isOwnPage || role !== UserRoleEnum.USER,
  });
  const { data: myData, isLoading } = useGetUserInformationQuery(userId || '', {
    skip: !userId,
  });
  const { data: sportsWithExperience, isLoading: isSportsExperienceLoading } =
    useGetUserSportExperienceByIdQuery(userId || '', {
      skip: !userId,
    });
  const { data: myChats } = useGetAllMyChatsQuery();
  const [createChat, { isLoading: isCreatingChat }] = useCreateChatMutation();
  const [inviteFriend, { isLoading: isInviteFriendLoading }] =
    useInviteToFriendMutation();
  const [deleteInviteToFriend, { isLoading: isDeletingInviteToFriend }] =
    useDeleteInviteToFriendMutation();
  const [unblockMyFriend, { isLoading: isUnblockMyFriendLoading }] =
    useUnblockMyFriendMutation();
  const [blockMyFriend, { isLoading: isBlockMyFriendLoading }] =
    useBlockMyFriendMutation();

  useEffect(() => {
    if (state?.introductionId) {
      toggleModal('INTRODUCTION_CHECK_MODAL');
    }
  }, [state]);

  const onEditProfileClick = useCallback(
    () => navigate('/profile'),
    [navigate]
  );

  const handleMessage = useCallback(
    (friendUserId: string | undefined) => {
      if (!friendUserId) {
        return;
      }

      return () => {
        dispatch(setChatState(ChatStateEnum.CHAT));

        if (myChats)
          for (const chat of myChats)
            if (chat.withUserId === friendUserId) {
              dispatch(setSelectedChat(chat));
              return navigate('/chat');
            }

        createChat(friendUserId)
          .unwrap()
          .then(chat => {
            dispatch(setSelectedChat(chat));
            navigate('/chat');
          })
          .catch(console.log);
      };
    },
    [myChats, createChat, dispatch, navigate]
  );

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const inviteFriendButtonHandle = async () => {
    if (!userId) {
      return;
    }

    try {
      await inviteFriend({ friendUserId: userId }).unwrap();
      setSnackbarOpen(true);
      setSnackbarMessage('Friend invitation sent successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const handleDeleteInviteToFriend = async () => {
    try {
      if (!userId) {
        return;
      }

      await deleteInviteToFriend({ friendUserId: userId }).unwrap();

      setSnackbarOpen(true);
      setSnackbarMessage('Invitation canceled');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
      console.error('Failed to cancel invitation: ', error);
    }
  };

  const unblockMyFriendButtonHandle = async () => {
    if (!userId) {
      return;
    }

    try {
      await unblockMyFriend(userId).unwrap();

      dispatch(friendApi.util?.invalidateTags(['friend']));
      setSnackbarOpen(true);
      setSnackbarMessage('Unblocked successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  const blockMyFriendButtonHandle = async () => {
    if (!userId) {
      return;
    }

    try {
      await blockMyFriend(userId).unwrap();

      dispatch(friendApi.util?.invalidateTags(['friend']));
      setSnackbarOpen(true);
      setSnackbarMessage('Blocked successfully');
      setSnackbarSeverity(SnackbarSeverityEnum.SUCCESS);
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarMessage(errorHelper(error));
      setSnackbarSeverity(SnackbarSeverityEnum.ERROR);
    }
  };

  return (
    <>
      {currentModal === 'REQUEST_INTRODUCTION_MODAL' && (
        <RequestIntroductionModal
          isOpen
          onClose={() => toggleModal('REQUEST_INTRODUCTION_MODAL')}
          userFirstName={myData?.firstName ?? null}
          userLastName={myData?.lastName ?? null}
        />
      )}
      {currentModal === 'INTRODUCTION_CHECK_MODAL' && (
        <IntroductionCheckModal
          isOpen
          onClose={() => toggleModal('INTRODUCTION_CHECK_MODAL')}
          introductionId={state?.introductionId}
        />
      )}
      <Box
        sx={{
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: { xs: '0 16px 16px', lg: 0 },
        }}
      >
        {isLoading || isSportsExperienceLoading ? (
          <Skeleton
            variant="rounded"
            sx={{
              width: '100%',
              minHeight: {
                xs: '310px',
                lg: '420px',
              },
              marginTop: {
                xs: '40px',
                lg: '0px',
              },
            }}
          />
        ) : (
          <>
            <Box
              display="flex"
              flexDirection={mobileView ? 'row' : 'column'}
              alignItems="center"
              sx={
                mobileView
                  ? {
                      columnGap: '20px',
                      width: '100%',
                      position: 'absolute',
                      top: '-25px',
                      left: '0',
                      padding: '0 22px',
                    }
                  : {}
              }
            >
              <Avatar
                alt="Profile"
                src={myData?.avatar?.fileUrl || ''}
                sx={{
                  [theme.breakpoints.up('xs')]: { width: 100, height: 100 },
                  [theme.breakpoints.up('lg')]: { width: 140, height: 140 },
                  border: `2px solid ${theme.palette.background.white}`,
                }}
              />
              <Box
                className="profile-name-location-container"
                m={mobileView ? '35px 0 0' : '28px 0'}
              >
                <Typography
                  variant="h3"
                  color={colors.fontDark}
                  textAlign={{ xs: 'left', lg: 'center' }}
                >
                  {`${myData?.firstName || ''} ${myData?.lastName || ''}`}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginTop: '0.375rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '0.375rem',
                    color: theme.palette.text.secondary,
                  }}
                >
                  {myData?.country || myData?.city ? (
                    <LocationIcon
                      width={'16px'}
                      height={'16px'}
                      viewBox="0 0 24 24"
                      sx={{
                        width: '16px',
                        height: '16px',
                        viewBox: '0 0 16px 16px',
                        fill: 'none',
                      }}
                    />
                  ) : (
                    ''
                  )}
                  {myData?.country ? `${myData?.country},` : ''}{' '}
                  {myData?.city ? myData?.city : ''}
                </Typography>
              </Box>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              width={'100%'}
              gap={'12px'}
              mb="36px"
              sx={
                mobileView
                  ? {
                      marginTop: '100px',
                    }
                  : {}
              }
            >
              <Grid container spacing={2} justifyContent="center">
                {sportsWithExperience &&
                  sportsWithExperience.map(
                    (
                      sportWithExperience: ISportExperienceResponse,
                      index: number
                    ) => (
                      <Grid
                        item
                        key={sportWithExperience.sportId}
                        xs={12}
                        sm={
                          sportsWithExperience.length % 2 !== 0 &&
                          index === sportsWithExperience.length - 1
                            ? 6
                            : 6
                        }
                        display="flex"
                        justifyContent={
                          sportsWithExperience.length % 2 !== 0 &&
                          index === sportsWithExperience.length - 1
                            ? 'center'
                            : 'flex-start'
                        }
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          rowGap={'12px'}
                          justifyContent="center"
                          alignItems="center"
                          padding="16px"
                          sx={{ bgcolor: 'background.light' }}
                          textAlign="center"
                          width="100%"
                        >
                          <Avatar
                            alt={sportWithExperience.sportName}
                            src={sportWithExperience.sportIconUrl || ''}
                            sx={{ width: 50, height: 50 }}
                          />
                          <Typography
                            variant="h4"
                            sx={{ fontWeight: 500, color: 'text.primary' }}
                          >
                            {sportWithExperience.sportName}
                          </Typography>
                        </Box>
                      </Grid>
                    )
                  )}
              </Grid>
            </Box>

            <Box className="profile-actions" width="100%">
              {isOwnPage && (
                <>
                  <Button
                    onClick={onEditProfileClick}
                    variant="outlined"
                    fullWidth
                    sx={{
                      height: '50px',
                      color: 'text.primary',
                      fontSize: '12px',
                      borderRadius: '4px',
                      borderColor: '#434E5F',
                    }}
                  >
                    Edit Profile
                  </Button>
                </>
              )}

              {!isOwnPage && (
                <>
                  {hasAccess && !isBlocked && (
                    <Stack direction="row" spacing="12px" mb="12px">
                      <Box
                        className="approved-profile-btn-box"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          width: '50px',
                          backgroundColor: colors.dark05,
                        }}
                      >
                        <ApprovedProfileIcon width={'50px'} height={'50px'} />
                      </Box>
                      <Button
                        disabled={isCreatingChat}
                        onClick={handleMessage(userId)}
                        variant="contained"
                        sx={{
                          flex: 1,
                          height: 50,
                          color: 'white',
                          fontSize: '12px',
                          marginBottom: '12px',
                          backgroundColor: '#F5431D',
                          borderRadius: '4px',
                        }}
                      >
                        send message
                      </Button>
                    </Stack>
                  )}

                  <Box display={'flex'} flexDirection="column" gap="0.75rem">
                    {role === UserRoleEnum.USER &&
                      !isOwnPage &&
                      userId &&
                      !hasAccess &&
                      !isCheckIfMyFriendInvitationExistLoading && (
                        <CustomButton
                          disabled={
                            isInviteFriendLoading ||
                            isDeletingInviteToFriend ||
                            checkIfMyFriendInvitationExist?.data === true
                          }
                          onClick={inviteFriendButtonHandle}
                          sx={{ p: '12px 16px', borderRadius: '4px' }}
                          buttonWidth="100%"
                          variant="contained"
                          variantType={ButtonTypeEnum.PRIMARY}
                          fullWidth
                        >
                          Connect Request
                        </CustomButton>
                      )}

                    {role === UserRoleEnum.USER &&
                      !isOwnPage &&
                      userId &&
                      !hasAccess &&
                      !isCheckIfMyFriendInvitationExistLoading && (
                        <CustomButton
                          disabled={
                            isDeletingInviteToFriend ||
                            isInviteFriendLoading ||
                            checkIfMyFriendInvitationExist?.data === false
                          }
                          onClick={handleDeleteInviteToFriend}
                          sx={{ p: '12px 16px', borderRadius: '4px' }}
                          buttonWidth="100%"
                          variant="contained"
                          variantType={ButtonTypeEnum.PRIMARY}
                          fullWidth
                        >
                          Cancel Request
                        </CustomButton>
                      )}

                    {role === UserRoleEnum.USER &&
                      !isOwnPage &&
                      userId &&
                      hasAccess &&
                      isBlocked && (
                        <CustomButton
                          disabled={isUnblockMyFriendLoading}
                          onClick={unblockMyFriendButtonHandle}
                          sx={{ p: '12px 16px', borderRadius: '4px' }}
                          buttonWidth="100%"
                          variant="contained"
                          variantType={ButtonTypeEnum.SECONDARY}
                          fullWidth
                        >
                          unblock user
                        </CustomButton>
                      )}

                    {role === UserRoleEnum.USER &&
                      !isOwnPage &&
                      userId &&
                      hasAccess &&
                      !isBlocked && (
                        <CustomButton
                          disabled={isBlockMyFriendLoading}
                          onClick={blockMyFriendButtonHandle}
                          sx={{ p: '12px 16px', borderRadius: '4px' }}
                          buttonWidth="100%"
                          variant="contained"
                          variantType={ButtonTypeEnum.SECONDARY}
                          fullWidth
                        >
                          block user
                        </CustomButton>
                      )}

                    {role === UserRoleEnum.USER &&
                      !hasAccess &&
                      hasMutualFriends && (
                        <Button
                          onClick={() =>
                            toggleModal('REQUEST_INTRODUCTION_MODAL')
                          }
                          variant="outlined"
                          fullWidth
                          sx={{
                            height: '50px',
                            color: 'text.primary',
                            fontSize: '12px',
                            borderRadius: '4px',
                            borderColor: '#434E5F',
                          }}
                        >
                          request introduction
                        </Button>
                      )}
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
      <SnackbarCustom
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default ProfileCard;
