import { Box, Divider, Grid, SxProps, Theme, Typography } from '@mui/material';
import Label from '../../../../../components/MUIComponents/Label';
import { LabelTypeEnum } from '../../../../../enums/label-enum';
import { ICareer } from '../../../../../interfaces/user-sport-background.interface';
import { foramtCareerDate } from '../../../../../utils/profile-page/format-career-date';

interface ICareersList {
  careers: ICareer[];
}
const CareersList = (props: ICareersList) => {
  const { careers } = props;
  return (
    <>
      {careers.map((career, index) => (
        <Box
          key={career.id}
          display={'flex'}
          flexDirection={'column'}
          marginTop={index === 0 ? '2.25rem' : 0}
        >
          {index !== 0 && <Divider sx={{ m: '2.25rem 0' }} />}
          <Grid container rowGap="28px" sx={gridContainerStyles}>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                League:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {career.leagueName}
              </Typography>
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Team:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {career.teamName}
              </Typography>
              {career.activeCareer && (
                <Label labelType={LabelTypeEnum.VERIFIED}>active athlete</Label>
              )}
            </Grid>
            <Grid item display="flex" flexDirection="row">
              <Typography className="label" variant="caption">
                Timeline:
              </Typography>
              <Typography className="plain-text" variant="body3">
                {foramtCareerDate(career.startDate)} -{' '}
                {!career.activeCareer
                  ? foramtCareerDate(career.endDate)
                  : 'Current'}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
    </>
  );
};

export default CareersList;

const gridContainerStyles: SxProps<Theme> = theme => ({
  '& .label': {
    width: 165,
    flexShrink: 0,
  },
  '& .plain-text': {
    fontWeight: 400,
    lineHeight: '140%',
    color: theme.palette.text.primary,

    textAlign: 'left',
    flexGrow: '1',
    wordWrap: 'break-word',
    whiteSpace: 'normal',
    minWidth: 0,
  },
  '& .MuiGrid-item': {
    width: '100%',
  },
});
