import * as yup from 'yup';

export const groupChatSchema = yup.object().shape({
  name: yup
    .string()
    .required('Title is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  description: yup.string().optional(),
  thumbnail: yup.mixed<File>().default(undefined).optional(),
});
