import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Logo from '../../Logo/Logo';

const userPages = [
  {
    label: 'About',
    to: '/',
  },
  {
    label: 'Help',
    to: '/',
  },
];

interface IFooterProps {
  footerSx?: SxProps;
  isLogoBlack?: boolean;
}
const Footer = (props: IFooterProps) => {
  const { footerSx, isLogoBlack = true } = props;
  return (
    <Box
      component={'footer'}
      sx={{
        width: '100%',
        flexShrink: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: '1440px',
        margin: '0 auto',
        padding: { xs: '1rem 0.75rem', md: '2.25rem 3.75rem' },
        borderTop: '1px solid',
        borderColor: 'background.lightDark',
        gap: { xs: '0.75rem', md: '0.75rem' },
        flexDirection: { xs: 'column-reverse', md: 'row' },
        ...footerSx,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: '0.75rem', md: '0.75rem' },
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
      >
        <Box
          component={Link}
          to={'/'}
          sx={{
            maxWidth: '9rem',
          }}
        >
          <Logo isBlack={isLogoBlack} />
        </Box>
        <Typography typography={'body3'}>
          © FreeAgent, 2024. All Rights Reserved.
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'row', sm: 'row' },
          gap: { xs: '0.375rem', md: '1.375rem' },
          p: { xs: 1, md: 0 },
        }}
      >
        {userPages.map(({ label, to }) => (
          <Link key={to + label} to={to}>
            <Typography typography={'body3'}>{label}</Typography>
          </Link>
        ))}
      </Box>
    </Box>
  );
};

export default Footer;
