import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { foramtCareerDate } from '../../../../../utils/profile-page/format-career-date';
import { IEducationResponse } from '../../../../../interfaces/education.interface';
import { gridContainerStyles } from '../AboutTab';

interface TabEducationEntryProps {
  education?: Partial<IEducationResponse>;
}

const TabEducationEntry: FC<TabEducationEntryProps> = ({ education }) => {
  const noData = 'No information';

  return (
    <Grid container rowGap="28px" sx={gridContainerStyles}>
      <Grid item display="flex" flexDirection="row">
        <Typography className="label" variant="caption">
          University:
        </Typography>
        <Typography className="plain-text" variant="body3">
          {education?.university ?? noData}
        </Typography>
      </Grid>
      <Grid item display="flex" flexDirection="row">
        <Typography className="label" variant="caption">
          Specialty:
        </Typography>
        <Typography className="plain-text" variant="body3">
          {education?.specialty ?? noData}
        </Typography>
      </Grid>
      <Grid item display="flex" flexDirection="row">
        <Typography className="label" variant="caption">
          Years:
        </Typography>
        {education?.startYear && education?.endYear ? (
          <Typography className="plain-text" variant="body3">
            {foramtCareerDate(education.startYear ?? '')} -{' '}
            {foramtCareerDate(education.endYear ?? '')}
          </Typography>
        ) : (
          <Typography className="plain-text" variant="body3">
            {noData}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default TabEducationEntry;
