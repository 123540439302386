import React, { FC, useEffect, useRef, useState, useCallback } from 'react';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import SearchBarItem from './SearchBarItem';
import { useGetPaginatedUsersQuery } from '../../../store/apiSlice/searchApi';
import { useNavigate } from 'react-router-dom';

interface SearchBarProps {}

const SearchBar: FC<SearchBarProps> = () => {
  const { theme } = useThemeContext();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const searchBarRef = useRef<HTMLDivElement | null>(null);
  const searchFieldRef = useRef<HTMLInputElement | null>(null);

  const { data: users, isLoading } = useGetPaginatedUsersQuery(
    search.length > 0
      ? {
          page: 1,
          limit: 5,
          search,
          sort: 'asc',
        }
      : {
          page: 1,
          limit: 5,
          search,
          sort: 'asc',
        },
    { skip: search.length === 0 }
  );

  const closeSearchBar = () => {
    setIsOpen(false);
    setSearch('');
  };

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const handleSearchSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && search.trim().length > 0) {
      event.preventDefault();
      navigate(`/global-search?query=${encodeURIComponent(search.trim())}`);
      closeSearchBar();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node)
      ) {
        closeSearchBar();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && searchFieldRef.current) {
      searchFieldRef.current.focus();
    }
  }, [isOpen]);

  if (!isOpen) {
    return (
      <IconButton
        onClick={() => setIsOpen(true)}
        sx={{
          ...iconStyles,
          '&:hover': {
            backgroundColor: theme.palette.text.accent,
            '& .MuiSvgIcon-root': {
              color: theme.palette.background.white,
            },
          },
        }}
      >
        <SearchIcon sx={iconSizes} />
      </IconButton>
    );
  }

  return (
    <Box ref={searchBarRef} sx={{ position: 'relative' }}>
      <TextField
        variant="outlined"
        placeholder="Search..."
        inputRef={searchFieldRef}
        value={search}
        onChange={handleSearchChange}
        onKeyDown={handleSearchSubmit}
        sx={searchFieldStyles}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ p: 0 }}>
              <SearchIcon sx={iconSizes} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="close search"
                onClick={closeSearchBar}
                sx={iconStyles}
              >
                <CloseIcon sx={iconSizes} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {search.trim().length > 0 && users && users.data.length > 0 && (
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '0.75rem 0',
            zIndex: 10,
            backgroundColor: theme.palette.background.white,
          }}
        >
          {users.data.map((user, index) => (
            <SearchBarItem user={user} onClose={closeSearchBar} key={index} />
          ))}

          {isOpen && !isLoading && !users?.data.length && search && (
            <Typography sx={{ color: theme.palette.text.secondary, mt: 1 }}>
              No results found.
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SearchBar;

// 🔹 Оптимізовані стилі
const iconStyles = {
  padding: 0,
  height: '2rem',
  width: '2rem',
  minHeight: '2rem',
  minWidth: '2rem',
};

const searchFieldStyles = {
  width: '100%',
  maxWidth: {
    md: '16rem',
    lg: '20.125rem',
  },
  '& .MuiInputBase-root': {
    height: '2rem',
    padding: '0.75rem 1rem',
  },
  '& .MuiOutlinedInput-input': {
    height: '2rem !important',
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    backgroundColor: 'transparent',
  },
};

const iconSizes = {
  width: '1.25rem',
  height: '1.25rem',
};
