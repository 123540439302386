import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface CandidatesIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const CandidatesIcon = ({
  iconColor,
  active,
  ...svgProps
}: CandidatesIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <circle cx="12" cy="10" r="4" stroke={color} strokeWidth="1.5" />
      <path
        d="M5 23C5 19 8 16 12 16C14 16 16 17 17 18"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect
        x="17"
        y="17"
        width="8"
        height="8"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M21 19V23" stroke={color} strokeWidth="1.5" />
      <path d="M19 21H23" stroke={color} strokeWidth="1.5" />
    </SvgIcon>
  );
};

export default CandidatesIcon;
