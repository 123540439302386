import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import CandidateSportFilter from '../../../../CandidatesPage/components/CandidatesSidebar/CandidatesFilters/CandidateSportFilter';
import { useSearchParams } from 'react-router-dom';
import { useGetPaginatedSportsQuery } from '../../../../../store/apiSlice/sportApi';
import FilterLayout from '../../../../../layouts/FilterLayout';
import CountryFilter from './CountryFilter';
import LeagueTeamFilter from './LeagueTeamFilter';
import FilterActionButtons from '../../../../CandidatesPage/components/CandidatesSidebar/CandidatesFilters/FilterActionButtons';
import {
  useGetLeaguesByMultipleSportsQuery,
  useGetTeamsByMultipleLeaguesQuery,
} from '../../../../../store/apiSlice/careerApi';
import { withUnderScore } from '../../../../../utils/withUnderScore';

interface PeopleFiltersProps {}

const PeopleFilters: FC<PeopleFiltersProps> = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [countryFilterState, setCountryFilterState] = useState<string>(
    searchParams.get('country') ?? ''
  );
  const [sportsFilterState, setSportsFilterState] = useState<string>(
    searchParams.get('sports') ?? ''
  );
  const [leagueFilterState, setLeagueFilterState] = useState<string>(
    searchParams.get('leagues') ?? ''
  );
  const [teamFilterState, setTeamFilterState] = useState<string>(
    searchParams.get('teams') ?? ''
  );
  const [limit, setLimit] = useState<number>(5);

  const {
    data: sportsPaginationResponse,
    isLoading,
    isError,
    error,
  } = useGetPaginatedSportsQuery({
    page: 1,
    limit,
    search: '',
    sort: 'asc',
  });

  const formattedSports = sportsFilterState
    ? sportsFilterState.split(',').map(withUnderScore).join(',')
    : '';

  const {
    data: leagues = [],
    isLoading: isLeagueLoading,
    isError: isLeagueErrors,
  } = useGetLeaguesByMultipleSportsQuery(formattedSports, {
    skip: !sportsFilterState,
  });

  const formattedLeagues = leagueFilterState
    ? leagueFilterState.split(',').map(withUnderScore).join(',')
    : '';

  const {
    data: teams = [],
    isLoading: isTeamLoading,
    isError: isTeamError,
  } = useGetTeamsByMultipleLeaguesQuery(formattedLeagues, {
    skip: !leagueFilterState,
  });

  const handleShowMore = () => {
    setLimit(prevLimit => prevLimit + 5);
  };

  const handleFilterReset = () => {
    setCountryFilterState('');
    setSportsFilterState('');
    setLeagueFilterState('');
    setTeamFilterState('');
    setLimit(5);

    setSearchParams(prevParams => {
      prevParams.set('country', '');
      prevParams.set('sports', '');
      prevParams.set('leagues', '');
      prevParams.set('teams', '');
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  const handleFilterApply = () => {
    setSearchParams(prevParams => {
      prevParams.set('country', countryFilterState);
      prevParams.set('sports', sportsFilterState);
      prevParams.set('leagues', leagueFilterState);
      prevParams.set('teams', teamFilterState);
      prevParams.set('page', '1');

      return prevParams;
    });
  };

  return (
    <Stack direction={'column'}>
      <FilterLayout name={'country'}>
        <CountryFilter
          countryFilterState={countryFilterState}
          setCountryFilterState={setCountryFilterState}
        />
      </FilterLayout>

      <CandidateSportFilter
        sports={sportsPaginationResponse?.data ?? []}
        isLoading={isLoading}
        isError={isError}
        totalSportsCount={sportsPaginationResponse?.total ?? 0}
        sportsFilterState={sportsFilterState}
        setSportsFilterState={setSportsFilterState}
        label={'sports'}
        error={error}
        onShowMore={handleShowMore}
      />

      <FilterLayout name={'League'}>
        <LeagueTeamFilter
          isActive={!!sportsFilterState}
          leagues={leagues ?? []}
          leagueFilterState={leagueFilterState}
          setLeagueFilterState={setLeagueFilterState}
        />
      </FilterLayout>

      <FilterLayout name={'Team'}>
        <LeagueTeamFilter
          isActive={!!leagueFilterState}
          teams={teams ?? []}
          teamFilterState={teamFilterState}
          setTeamFilterState={setTeamFilterState}
        />
      </FilterLayout>

      <FilterActionButtons
        handleFilterReset={handleFilterReset}
        handleFilterApply={handleFilterApply}
      />
    </Stack>
  );
};

export default PeopleFilters;
