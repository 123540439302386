import { Badge, IconButton } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { INotification } from '../../../interfaces/notification.interface';
import {
  useGetAllMyNotificationsQuery,
  useGetMyUnreadNotificationsCountQuery,
  useMarkMyNotificationAsReadMutation,
} from '../../../store/apiSlice/notificationApi';
import BellIcon from '../../Icons/BellIcon';
import NotificationList from './NotificationList';

const LIMIT = 5;
const POLLING_INTERVAL = 20000;
const NOTIFICATION_MARKED_AS_READ_RES =
  'Notification marked as read successfully';

const NotificationsMenu = () => {
  const [isLoadMore, setIsLoadMore] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const queryParams = {
    page: currentPage,
    limit: LIMIT,
  };

  const { data: allNotifications, refetch } = useGetAllMyNotificationsQuery(
    queryParams,
    {
      refetchOnMountOrArgChange: true,
      refetchOnFocus: true,
    }
  );

  const { data: unreadNotificationsCount } =
    useGetMyUnreadNotificationsCountQuery(undefined, {
      pollingInterval: POLLING_INTERVAL,
    });

  const [markMyNotificationAsRead, { isLoading: isMarkingAsRead }] =
    useMarkMyNotificationAsReadMutation();

  useEffect(() => {
    if (unreadNotificationsCount?.data)
      setNewNotificationsCount(unreadNotificationsCount.data);
  }, [unreadNotificationsCount]);

  useEffect(() => {
    const processNotofications = async () => {
      if (allNotifications && allNotifications?.data) {
        let tempNotifications = allNotifications.data;

        if (open) {
          const readNotificationIndeces =
            await handleReadNotifications(tempNotifications);
          tempNotifications = tempNotifications.map(n => {
            if (readNotificationIndeces.includes(n.id)) {
              setNewNotificationsCount(prev => prev - 1);

              return { ...n, isRead: true };
            }

            return n;
          });
        }

        const hasNext =
          allNotifications.limit * (allNotifications.page - 1) +
            allNotifications.limit <
          allNotifications.total;

        setIsLoadMore(hasNext);

        if (currentPage === 1) {
          return setNotifications(tempNotifications);
        }

        setNotifications(prev => {
          // Create a map of notifications to efficiently handle replacements
          const existingMap = new Map(prev.map(notif => [notif.id, notif]));

          // Update the map with tempNotifications (prioritizing replacements)
          tempNotifications.forEach(notif => {
            existingMap.set(notif.id, notif);
          });

          // Preserve order by iterating over the original array first, then adding new items
          const seen = new Set<string>();
          const mergedNotifications: INotification[] = [];

          prev.forEach(notif => {
            if (existingMap.has(notif.id)) {
              mergedNotifications.push(existingMap.get(notif.id)!);
              seen.add(notif.id);
            }
          });

          tempNotifications.forEach(notif => {
            if (!seen.has(notif.id)) {
              mergedNotifications.push(notif);
            }
          });

          return mergedNotifications;
        });
      }
    };
    processNotofications();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifications]);

  const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setCurrentPage(1);

    let tempNotifications = notifications;
    const readNotificationIndeces =
      await handleReadNotifications(tempNotifications);

    tempNotifications = tempNotifications.map(n => {
      if (readNotificationIndeces.includes(n.id)) {
        setNewNotificationsCount(prev => prev - 1);

        return { ...n, isRead: true };
      }

      return n;
    });
    setNotifications(tempNotifications.slice());
    refetch();
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRemoveNotification = useCallback(
    (notificationId: string) => {
      if (notifications.find(el => el.id === notificationId && !el.isRead)) {
        setNewNotificationsCount(prev => prev - 1);
      }
      setCurrentPage(1);
    },
    [notifications]
  );

  const handleMarkAsRead = useCallback(() => {
    if (newNotificationsCount <= 0) return;
    setNewNotificationsCount(prev => prev - 1);
  }, [newNotificationsCount]);

  const handleReadNotifications = useCallback(
    async (notifications: INotification[]) => {
      const markNotificationsAsRead = async (
        notifications: INotification[]
      ) => {
        try {
          return await Promise.allSettled(
            notifications.map(async n => {
              const res = await markMyNotificationAsRead({
                notificationId: n.id,
                skipInvalidation: true,
              });
              return (res.data as any)?.data === NOTIFICATION_MARKED_AS_READ_RES
                ? n.id
                : Promise.reject(`Unable to read notification ${n.id}`);
            })
          );
        } catch (error) {
          console.warn('Unable to read notifications. Something went wrong!');
          return [];
        }
      };
      const unreadNotifications = notifications.filter(n => !n.isRead);
      const res = await markNotificationsAsRead(unreadNotifications);

      return res?.filter(n => n.status === 'fulfilled').map(n => n.value);
    },
    [markMyNotificationAsRead]
  );

  const onLoadMoreNotifications = useCallback(() => {
    setCurrentPage(prev => prev + 1);
  }, []);

  return (
    <>
      <Badge
        component={IconButton}
        onClick={handleClick}
        badgeContent={newNotificationsCount > 0 ? newNotificationsCount : null}
        color="error"
        sx={{
          m: 0,
          p: 0,
          minWidth: 0,
          width: 'auto',
          height: 'auto',
          minHeight: 'auto',
        }}
      >
        <BellIcon />
      </Badge>
      <NotificationList
        notifications={notifications}
        isLoadMore={isLoadMore}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onLoadMore={onLoadMoreNotifications}
        onDelete={handleRemoveNotification}
        onMarkAsRead={handleMarkAsRead}
      />
    </>
  );
};

export default NotificationsMenu;
