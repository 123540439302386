import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  SxProps,
  Theme,
} from '@mui/material';
import {
  ContentBlock,
  ContentState,
  DraftHandleValue,
  EditorProps,
  EditorState,
  Modifier,
  RawDraftContentBlock,
  convertToRaw,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import {
  ForwardedRef,
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { ChangeHandler, UseFormRegisterReturn } from 'react-hook-form';
import { throttle } from '../../../../utils/throttle';
import editorStyles from './editorStyles.module.css';
import mentionsStyles from './mentionsStyles.module.css';

// for more details see doc: https://www.draft-js-plugins.com/plugin/mention

const throttledSetFinalContent = throttle(
  (
    event: {
      target: any;
      type?: any;
    },
    callback: ChangeHandler
  ) => callback(event),
  500
);

const throttledSetMentions = throttle(
  (mentions: Suggestion[], callback: (mentions: Suggestion[]) => void) =>
    callback(mentions),
  500
);

export interface Suggestion {
  name: string;
  link: string;
  avatar?: string | undefined;
  userId: string;
}

interface MentionsTextFieldProps
  extends Omit<EditorProps, 'plugins' | 'editorState' | 'onChange'> {
  sx?: SxProps;
  suggestions: Suggestion[];
  setMentions?: (mentions: Suggestion[]) => void;
  onInputChange?: (value: string) => void;
  onSearchChange: (event: { value: string }) => void;
  height?: number | string;
  minHeight?: number | string;
  name?: string;
  label?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn<any>;
  error?: boolean;
  helperText?: string;
}

export interface EditorRef extends Partial<Editor> {
  updateEditorState: (newValue: string) => void;
}

const MentionsTextField = forwardRef<EditorRef, MentionsTextFieldProps>(
  (
    {
      sx,
      suggestions,
      setMentions,
      onInputChange,
      onSearchChange,
      handleKeyCommand,
      keyBindingFn,
      height,
      minHeight,
      name,
      label,
      maxLength = Infinity,
      register,
      error,
      helperText,
      ...rest
    }: MentionsTextFieldProps,
    ref: ForwardedRef<EditorRef>
  ) => {
    const {
      onChange: onChangeRegister,
      name: registerName,
      ...restRegister
    } = register ?? {};
    const { MentionSuggestions, plugins } = useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        theme: mentionsStyles,
        mentionComponent(mentionProps) {
          return (
            <span
              className={mentionProps.className}
              // eslint-disable-next-line no-alert
              onClick={() =>
                console.log('Clicked on the Mention!', mentionProps)
              }
            >
              {mentionProps.decoratedText}
            </span>
          );
        },
      });
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin];
      return { plugins, MentionSuggestions };
    }, []);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
      updateEditorState: (newValue: string) => {
        const newEditorState = EditorState.push(
          editorState,
          ContentState.createFromText(newValue),
          'insert-characters'
        );
        setEditorState(newEditorState);
      },
    }));

    const onOpenChange = useCallback((_open: boolean) => {
      setOpen(_open);
    }, []);

    const handleBeforeInput = useCallback(
      (
        chars: string,
        editorState: EditorState,
        eventTimeStamp: number
      ): DraftHandleValue => {
        const currentContent = editorState.getCurrentContent();
        const currentText = currentContent.getPlainText();

        // Prevent further input if max length is exceeded
        if (currentText.length + chars.length > maxLength) {
          return 'handled';
        }
        return 'not-handled';
      },
      [maxLength]
    );

    const extractData = useCallback((editorState: EditorState): string => {
      const contentState = editorState.getCurrentContent();
      const rawContent = convertToRaw(contentState);

      // Create a mapping of numeric indices to entity keys
      const entityMap: any = {};
      let entityIndex = 0;

      contentState.getBlockMap().forEach(block => {
        block?.findEntityRanges(
          character => {
            const entityKey = character.getEntity();
            if (entityKey !== null) {
              entityMap[entityIndex] = entityKey;
              entityIndex += 1;
            }
            return false; // This ensures we process each character individually.
          },
          () => {} // Callback function for range, not needed here.
        );
      });

      const processBlock = (block: RawDraftContentBlock) => {
        const { text, entityRanges } = block;
        let result = '';
        let lastOffset = 0;

        entityRanges.forEach(range => {
          const entityKey = entityMap[range.key]; // Map numeric index to string key

          try {
            const entity = contentState.getEntity(entityKey);

            if (entity.getType() === 'mention') {
              const mentionData = entity.getData();
              const mentionText = text.slice(
                range.offset,
                range.offset + range.length
              );

              // Append text before the mention
              result += text.slice(lastOffset, range.offset);
              // Append the mention as a markdown link
              result += `[${mentionText}](${mentionData.mention.link || '#'})`;

              lastOffset = range.offset + range.length;
            }
          } catch (error) {
            console.warn(
              `Entity with key ${entityKey} could not be retrieved:`,
              error
            );
          }
        });

        // Append any remaining text after the last entity
        result += text.slice(lastOffset).replaceAll(/\n/g, '<br>');
        return result;
      };

      // Process blocks and assemble the final markdown text
      const markdownText = rawContent.blocks
        .map(block => processBlock(block))
        .join('&nbsp;\n');

      return markdownText;
    }, []);

    const extractMentions = useCallback(
      (editorState: EditorState): Suggestion[] => {
        const contentState = editorState.getCurrentContent();
        const raw = convertToRaw(contentState);
        let mentionedUsers = [];
        for (let key in raw.entityMap) {
          const ent = raw.entityMap[key];
          if (ent.type === 'mention') {
            mentionedUsers.push(ent.data.mention);
          }
        }

        return mentionedUsers;
      },
      []
    );

    const handlePastedText = useCallback(
      (
        pastedText: string,
        html: string | undefined,
        editorState: EditorState
      ): DraftHandleValue => {
        const currentContent = editorState.getCurrentContent();
        const currentText = currentContent.getPlainText();

        // Check if pasted text exceeds max length
        if (currentText.length + pastedText.length > maxLength) {
          const allowedText = pastedText.slice(
            0,
            maxLength - currentText.length
          );
          const newContent = Modifier.insertText(
            currentContent,
            editorState.getSelection(),
            allowedText
          );
          const newEditorState = EditorState.push(
            editorState,
            newContent,
            'insert-characters'
          );
          setEditorState(newEditorState);

          if (onChangeRegister && registerName) {
            throttledSetFinalContent(
              {
                type: 'change',
                target: {
                  name: registerName,
                  value: extractData(newEditorState),
                },
              },
              onChangeRegister
            );
          }

          if (onInputChange) {
            onInputChange(extractData(newEditorState));
          }

          return 'handled';
        }
        return 'not-handled';
      },
      [maxLength, registerName, extractData, onChangeRegister, onInputChange]
    );

    // maxLength if it doesn't work, try changing it to:
    // https://stackoverflow.com/questions/46044251/how-to-limit-max-length-of-draft-js
    const handleOnChange = useCallback(
      (editorState: EditorState) => {
        const currentContent = editorState.getCurrentContent();
        const currentText = currentContent.getPlainText();
        let newEditorState: EditorState | null = null;

        // Truncate text if it exceeds max length
        if (currentText.length > maxLength) {
          const truncatedText = currentText.slice(0, maxLength);
          // Check if the current content already matches the truncated content
          if (currentText === truncatedText) {
            return;
          }

          // Create new content with the truncated text
          const selectionState = editorState.getSelection();
          const anchorKey = selectionState.getAnchorKey();
          const currentBlock = currentContent.getBlockForKey(anchorKey);

          // Create a new ContentBlock with the truncated text
          const newBlock = new ContentBlock({
            key: currentBlock.getKey(),
            type: currentBlock.getType(),
            text: truncatedText,
            characterList: currentBlock
              .getCharacterList()
              .slice(0, truncatedText.length),
            data: currentBlock.getData(),
          });

          // Replace the block in the blockMap
          const newBlockMap = currentContent
            .getBlockMap()
            .set(anchorKey, newBlock);
          const newContent = currentContent.set(
            'blockMap',
            newBlockMap
          ) as ContentState;

          const truncatedEditorState = EditorState.push(
            editorState,
            newContent,
            'remove-range'
          );
          newEditorState = truncatedEditorState;
        } else {
          newEditorState = editorState;
        }

        // Update local state
        setEditorState(newEditorState);

        if (onChangeRegister && registerName) {
          throttledSetFinalContent(
            {
              type: 'change',
              target: {
                name: registerName,
                value: extractData(newEditorState),
              },
            },
            onChangeRegister
          );
        }

        if (setMentions) {
          throttledSetMentions(extractMentions(newEditorState), setMentions);
        }

        if (onInputChange) {
          onInputChange(extractData(newEditorState));
        }
      },
      [
        maxLength,
        registerName,
        setMentions,
        extractData,
        onInputChange,
        extractMentions,
        onChangeRegister,
      ]
    );

    return (
      <Box sx={sx}>
        <FormControl
          error={!!error}
          fullWidth
          sx={{ height, position: 'relative' }}
        >
          {label && (
            <FormLabel
              id={`${name}-mentions-text-field-label`}
              focused={false}
              error={false}
              sx={labelStyles}
            >
              {label}
            </FormLabel>
          )}
          <Box
            className={editorStyles.editor + ' editor-styles-custom'}
            sx={{
              '& .public-DraftEditor-content': {
                minHeight: minHeight,
              },
            }}
          >
            <Editor
              editorState={editorState}
              handleBeforeInput={handleBeforeInput}
              handlePastedText={handlePastedText}
              onChange={handleOnChange}
              plugins={plugins}
              ref={ref as any}
              {...restRegister}
              {...rest}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFn}
            />
            <MentionSuggestions
              open={open}
              onOpenChange={onOpenChange}
              onSearchChange={onSearchChange}
              suggestions={suggestions}
              // popoverContainer={CustomPopUp}
              onAddMention={() => {
                // get the mention object selected
              }}
            />
          </Box>
          <FormHelperText id="helper-text">{helperText}</FormHelperText>
        </FormControl>
      </Box>
    );
  }
);

export default memo(MentionsTextField);

const labelStyles: SxProps<Theme> = theme => ({
  color: theme.palette.text.primary,
  fontFamily: 'ES Klarheit Kurrent TRIAL',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: '800',
  lineHeight: '12px',
  letterSpacing: '0.48px',
  textTransform: 'uppercase',
  pb: '10px',
});

// add suggestions loader here
// const CustomPopUp = (props: PopoverProps) => {
//   return (
//     <Box bgcolor="white">
//       hello
//       {props.children}
//     </Box>
//   );
// };
