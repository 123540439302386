import * as React from 'react';
import { cloneElement, FC, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import { ListItemIcon, Typography, Avatar } from '@mui/material';
import BurgerMenuIcon from '../../Icons/BurgerMenuIcon';
import CloseX from '../../Icons/CloseX';
import Logo from '../../Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';
import DotIcon from '../../Icons/DotIcon';
import Footer from '../Footer/Footer';

interface MobileMenuPage {
  label: string;
  to: string;
  navigateFunction?: () => void;
  icon?: any;
}

interface NavigationMobileMenuProps {
  pages: MobileMenuPage[];
  avatarUrl: string;
}

const NavigationMobileMenu: FC<NavigationMobileMenuProps> = ({
  pages,
  avatarUrl,
}) => {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const DrawerList = (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        {pages.map(page => {
          const isActive = location.pathname === page.to;
          return (
            <ListItem
              key={page.label}
              disablePadding
              sx={{
                bgcolor: 'background.white',
                borderRadius: '1px',
                p: '1rem',
              }}
            >
              <Link
                onClick={page?.navigateFunction && page.navigateFunction}
                to={page.to}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  width: '100%',
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 'unset',
                    display: 'flex',
                    alignItems: 'center',
                    mr: '12px',
                  }}
                >
                  {isActive && (
                    <DotIcon sx={{ width: 8, height: 8, mr: '12px' }} />
                  )}
                  {cloneElement(page.icon, {
                    active: isActive,
                  })}
                </ListItemIcon>
                <Typography
                  variant="body4"
                  fontWeight={700}
                  color={isActive ? 'button.primary' : 'text.secondary'}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {page.label}
                </Typography>
              </Link>
            </ListItem>
          );
        })}
      </List>

      <Box sx={{ marginTop: 'auto' }}>
        <Footer isLogoBlack={false} />
      </Box>
    </Box>
  );

  return (
    <Box>
      <Button
        onClick={toggleDrawer(true)}
        sx={{ p: '0px', m: '0px', minWidth: 'fit-content' }}
      >
        <BurgerMenuIcon />
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
          padding={'0.75rem 1rem'}
          height={'4.5rem'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            gap={'1rem'}
            alignItems={'center'}
          >
            <CloseX onClick={() => setOpen(false)} />
            <Box
              component={Link}
              to={'/'}
              sx={{ maxWidth: '9rem', marginRight: { xs: 'auto', lg: '0px' } }}
            >
              <Logo />
            </Box>
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            alignItems={'center'}
            gap={'0.5rem'}
          >
            <Avatar alt="Profile" src={avatarUrl} />
          </Box>
        </Box>
        {DrawerList}
      </Drawer>
    </Box>
  );
};

export default NavigationMobileMenu;
