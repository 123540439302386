import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 0,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.text.disabled,
    ...theme.applyStyles('dark', {
      backgroundColor: theme.palette.grey[200],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.palette.success.main,
    ...theme.applyStyles('dark', {
      backgroundColor: 'orange',
    }),
  },
}));

export default BorderLinearProgress;
