import { Box, IconButton, SxProps, useMediaQuery } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Photo } from '../../../interfaces/media.interface';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import BackArrowIcon from '../../Icons/BackArrowIcon';
import CloseIcon from '../../Icons/CloseIcon';
import PhotoArrowRightIcon from '../../Icons/PhotoArrowRightIcon';
import TrashRedIcon from '../../Icons/TrashRedIcon';
import BaseModal from '../../MUIComponents/BaseModal';
import IconRoundBackground from '../../MUIComponents/IconRoundBackground';
import VideoPlayer from '../../VideoPlayer/VideoPlayer';

interface MediaPreviewModalProps {
  isVideo?: boolean;
  files: Photo[];
  currentIndex: number;
  isOpen?: boolean;
  onClose: () => void;
  clickNext: () => void;
  clickPrev: () => void;
  deleteFile?: () => void;
  needNextPrevButtons?: boolean;
}

const MediaPreviewModal = ({
  isVideo = false,
  files,
  currentIndex,
  onClose,
  isOpen = false,
  clickNext,
  clickPrev,
  deleteFile,
  needNextPrevButtons = true,
}: MediaPreviewModalProps) => {
  const { theme } = useThemeContext();
  const tabletAndBigger = useMediaQuery(theme.breakpoints.up('md'));
  const mediaRef = useRef<HTMLVideoElement | null>(null);
  const [currentMedia, setCurrentMedia] = useState<HTMLVideoElement | null>(
    null
  ); // State to track when the ref is assigned
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
    arrowsOffset: 100,
  });

  useEffect(() => {
    const updateImageDimensions = () => {
      if (!isVideo && files[currentIndex]?.fileUrl) {
        const img = new Image();
        img.src = files[currentIndex]?.fileUrl;
        img.onload = () => {
          const containerWidth = Math.min(window.innerWidth * 0.7, 1300);
          const widthRatio = containerWidth / img.width;
          const containerHeight = Math.min(window.innerHeight * 0.8, 1200);
          const heightRatio = containerHeight / img.height;
          const width = img.width * Math.min(widthRatio, heightRatio);
          const height = img.height * Math.min(widthRatio, heightRatio);
          const arrowsOffset = (containerWidth + 200 - width) / 2;
          setImageDimensions({
            width,
            height,
            arrowsOffset,
          });
        };
      }
      if (isVideo && files[currentIndex]?.fileUrl && currentMedia) {
        const vid = currentMedia;
        if (!vid) {
          return;
        }

        vid.src = files[currentIndex]?.fileUrl;
        vid.onloadedmetadata = () => {
          const containerWidth = Math.min(window.innerWidth * 0.7, 1300);
          const widthRatio = containerWidth / vid.videoWidth;
          const containerHeight = Math.min(window.innerHeight * 0.8, 1200);
          const heightRatio = containerHeight / vid.videoHeight;
          const width = vid.videoWidth * Math.min(widthRatio, heightRatio);
          const height = vid.videoHeight * Math.min(widthRatio, heightRatio);
          const arrowsOffset = (containerWidth + 200 - width) / 2;
          setImageDimensions({
            width,
            height,
            arrowsOffset,
          });
        };
      }
    };

    updateImageDimensions();

    window.addEventListener('resize', updateImageDimensions);

    return () => {
      window.removeEventListener('resize', updateImageDimensions);
    };
  }, [currentIndex, files, isVideo, currentMedia]);

  const boxSx: SxProps = tabletAndBigger
    ? {
        p: 0,
        border: 'none',
        overflow: 'visible',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto !important',
        height: 'fit-content',
        boxShadow: 'unset',
        bgcolor: 'transparent',
      }
    : { overflow: 'auto' };

  useEffect(() => {
    if (currentMedia) {
      currentMedia.pause();
    }
  }, [currentMedia, isOpen]);

  const onCloseHandler = () => {
    if (currentMedia) {
      currentMedia.pause();
    }
    onClose();
  };

  const header = (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      mb={3}
      sx={{
        position: { md: 'absolute' },
        zIndex: { md: 1000 },
        right: { md: 12 },
        top: { md: 12 },
      }}
    >
      <IconButton sx={{ p: 0 }} onClick={onClose}>
        <IconRoundBackground
          width={50}
          height={50}
          backgroundColor={
            tabletAndBigger ? 'secondary.main' : 'secondary2.main'
          }
        >
          <CloseIcon sx={{ path: { md: { stroke: 'white' } } }} />
        </IconRoundBackground>
      </IconButton>
    </Box>
  );

  const footer = tabletAndBigger ? (
    <Box display="flex" justifyContent="space-between">
      {needNextPrevButtons && files.length > 1 && (
        <>
          {' '}
          <IconButton
            sx={{
              bgcolor: 'secondary.main',
              position: 'absolute',
              top: '50%',
              left: `-${imageDimensions.arrowsOffset}px`,
              transform: 'rotate(180deg) translate(0, 50%)',
              zIndex: 1000,
            }}
            disabled={currentIndex === 0}
            onClick={clickPrev}
          >
            <PhotoArrowRightIcon />
          </IconButton>
          <IconButton
            sx={{
              bgcolor: 'secondary.main',
              position: 'absolute',
              top: '50%',
              right: `-${imageDimensions.arrowsOffset}px`,
              transform: 'translate(0, -50%)',
              zIndex: 1000,
            }}
            disabled={currentIndex === files.length - 1}
            onClick={clickNext}
          >
            <PhotoArrowRightIcon />
          </IconButton>
        </>
      )}
    </Box>
  ) : (
    <Box display="flex" justifyContent="space-between" mt={4}>
      <IconButton sx={{ p: 0 }} onClick={onClose}>
        <IconRoundBackground
          width={50}
          height={50}
          backgroundColor="secondary2.main"
        >
          <BackArrowIcon />
        </IconRoundBackground>
      </IconButton>
      <IconButton sx={{ p: 0 }} onClick={deleteFile}>
        <IconRoundBackground
          width={50}
          height={50}
          backgroundColor="secondary2.main"
        >
          <TrashRedIcon />
        </IconRoundBackground>
      </IconButton>
    </Box>
  );

  return (
    <BaseModal
      boxSx={{ ...boxSx }}
      disableEscapeButton
      disableContentMargin
      isOpen={isOpen}
      toggle={onCloseHandler}
      disableDefaultFooter
      header={{ component: header }}
      footer={{ component: footer }}
    >
      {!isVideo && (
        <Box
          component="img"
          src={files[currentIndex]?.fileUrl}
          alt={'media preview'}
          sx={{
            width: { xs: '100%', md: `${imageDimensions.width}px` },
            height: { xs: 'auto', md: `${imageDimensions.height}px` },
            objectFit: 'contain',
            display: 'block',
          }}
        />
      )}
      {isVideo && (
        <VideoPlayer
          height={tabletAndBigger ? imageDimensions.height : '100%'}
          width={tabletAndBigger ? imageDimensions.width : '100%'}
          setRef={(node: HTMLVideoElement) => {
            mediaRef.current = node;
            setCurrentMedia(node);
          }}
          url={files[currentIndex]?.fileUrl}
        />
      )}
    </BaseModal>
  );
};

export default MediaPreviewModal;
