import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import {
  IEducation,
  IEducationRequest,
  IEducationResponse,
} from '../../interfaces/education.interface';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';
import { EducationLevelEnum } from '../../enums/education-type-enum';

const userEducationApi = createApi({
  reducerPath: 'userEducationApi',
  baseQuery: baseQueryWithInterceptor(config.BASE_USER_MICROSERVICE_API_URL),
  tagTypes: ['userEducation'],

  endpoints: builder => ({
    getMyEducation: builder.query<IEducation[], void>({
      query: () => '/user-education',
      providesTags: ['userEducation'],
      transformResponse: (response: IEducationResponse[]) => {
        return response.map(
          ({
            id,
            university,
            specialty,
            idSpecialty,
            idUniversity,
            level,
            userId,
            startYear,
            endYear,
            createdAt,
            updatedAt,
          }) => ({
            educationId: id,
            university,
            specialty,
            idSpecialty,
            idUniversity,
            level,
            userId,
            startYear,
            endYear,
            createdAt,
            updatedAt,
          })
        );
      },
    }),
    createUpdateEducation: builder.mutation<
      IEducationResponse[],
      IEducationRequest
    >({
      query: (data: IEducationRequest) => {
        return {
          url: `/user-education/save`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['userEducation'],
    }),
    deleteEducation: builder.mutation<IEducationResponse, string>({
      query(id) {
        return {
          url: `/user-education/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['userEducation'],
    }),
  }),
});

export const {
  useGetMyEducationQuery,
  useCreateUpdateEducationMutation,
  useDeleteEducationMutation,
} = userEducationApi;

export default userEducationApi;
