import * as yup from 'yup';
import { baseRegistrationSchema } from './auth-validation';

export const companySchema = baseRegistrationSchema.shape({});

export const companyDetailsSchema = yup.object().shape({
  companyName: yup
    .string()
    .required('Company name is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  address: yup
    .string()
    .required('Address is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  city: yup
    .string()
    .required('City is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  state: yup
    .string()
    .required('State / Province is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  country: yup
    .string()
    .required('Country is required')
    .transform((value: string) => value.replace(/\s+/g, ' ').trim()),
  zipCode: yup
    .string()
    .matches(/^\d{5}(-\d{4})?$/, 'Invalid ZIP code format')
    .required('ZIP code is required'),
  phone: yup
    .string()
    .matches(
      /^\+?[1-9]\d{9,14}$/,
      'Invalid phone number format. The phone number should only contain digits, start with a non-zero digit, and may optionally include a "+" for international numbers.'
    )
    .required('Phone is required'),

  contactEmail: yup
    .string()
    .email('Invalid contact email')
    .required('Contact email is required'),
  website: yup
    .string()
    .url('Invalid website URL')
    .required('Website is required'),
});
