import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { updatePasswordSchema } from '../../../validation/auth-validation';
import { useUpdatePasswordMutation } from '../../../store/apiSlice/auth/authApi';
import { Box, Typography, useMediaQuery } from '@mui/material';
import CustomButton from '../../MUIComponents/CustomButton';
import RightArrowIcon from '../../Icons/RightArrowIcon';
import Input from '../../MUIComponents/Input';
import { errorHelper } from '../../../utils/helper/error-helper';
import { useThemeContext } from '../../../theme/ThemeContextProvider';
import SnackbarCustom from '../../MUIComponents/SnackbarCustom';
import { SnackbarSeverityEnum } from '../../../enums/snackbar-severity-enum';
import ConfirmIcon from '../../Icons/ConfirmIcon';
import CloseX from '../../Icons/CloseX';

interface UpdatePasswordFormInputs {
  confirmationCode: string;
  newPassword: string;
  confirmPassword: string;
  email: string;
}

const passwordValidationRules = [
  {
    label: 'Minimum 8 characters',
    test: (value: string) => value.length >= 8,
  },
  {
    label: '1 lowercase letter',
    test: (value: string) => /[a-z]/.test(value),
  },
  {
    label: '1 uppercase letter',
    test: (value: string) => /[A-Z]/.test(value),
  },
  {
    label: 'Contains a number',
    test: (value: string) => /[0-9]/.test(value),
  },
  {
    label: 'Contains a symbol',
    test: (value: string) => /[@$!%*?&#]/.test(value),
  },
  {
    label: 'Passwords must match',
    test: (value: string, confirmValue: string) => {
      if (!value || !confirmValue) {
        return false;
      }
      return value === confirmValue;
    },
  },
];

const UpdatePassword = () => {
  const { state } = useLocation();
  const { email } = state || '';
  const navigate = useNavigate();

  const { theme } = useThemeContext();
  const mobileView = useMediaQuery(theme.breakpoints.down('md'));

  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarError, setSnackbarError] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UpdatePasswordFormInputs>({
    resolver: yupResolver(updatePasswordSchema, { abortEarly: false }),
    defaultValues: {
      email,
    },
  });
  const [updatePassword, { isLoading, data: responseData }] =
    useUpdatePasswordMutation();

  const onSubmit = async (data: UpdatePasswordFormInputs) => {
    try {
      await updatePassword({
        ConfirmationCode: data.confirmationCode,
        newPassword: data.newPassword,
        email: data.email,
      }).unwrap();
      setSnackbarMessage('Password updated successfully');
      setSnackbarError(false);
      navigate('/login', { replace: true, state: { email: data.email || '' } });
    } catch (error) {
      setSnackbarMessage(errorHelper(error));
      setSnackbarError(true);
      console.error('Update password failed:', error);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarMessage(null);
  };

  const newPasswordValue = watch('newPassword') || '';
  const confirmPasswordValue = watch('confirmPassword') || '';

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="3rem"
      width={'min(26.5625rem,100%)'}
    >
      <Typography variant="h2">Update Password</Typography>
      <Box display="flex" flexDirection="column" gap="1.375rem">
        <Input
          disabled
          caption="Email:"
          type="email"
          variant="outlined"
          fullWidth
          placeholder="Enter email address"
          register={register('email')}
          error={!!errors.email}
          helperText={errors.email ? errors.email.message : ''}
        />
        <Input
          caption="Confirmation Code:"
          variant="outlined"
          fullWidth
          placeholder="Enter confirmation code"
          register={register('confirmationCode')}
          error={!!errors.confirmationCode}
          helperText={
            errors.confirmationCode ? errors.confirmationCode.message : ''
          }
        />
        <Input
          caption="New Password:"
          type="password"
          variant="outlined"
          fullWidth
          placeholder="Enter new password"
          register={register('newPassword')}
          autoComplete="new-password"
        />
        <Input
          caption="Confirm Password:"
          type="password"
          variant="outlined"
          fullWidth
          placeholder="Confirm new password"
          register={register('confirmPassword', {
            validate: value =>
              value === watch('newPassword') || 'Passwords do not match',
          })}
        />
      </Box>

      {/* Real-time Password Validation Checklist */}
      <Box display="flex" flexDirection="column" gap="6px">
        {passwordValidationRules.map((rule, index) => (
          <Box key={rule.label} display="flex" alignItems="center" gap="13px">
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                bgcolor: rule.test(newPasswordValue, confirmPasswordValue)
                  ? 'background.mint'
                  : 'button.light',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
              }}
            >
              {rule.test(newPasswordValue, confirmPasswordValue) ? (
                <ConfirmIcon
                  stroke={theme.palette.success.light}
                  sx={{
                    fill: 'transparent',
                    width: '17px',
                    height: '17px',
                  }}
                />
              ) : (
                <CloseX
                  stroke={theme.palette.text.accent}
                  sx={{
                    width: '17px',
                    height: '17px',
                  }}
                />
              )}
            </Box>
            <Typography
              variant="caption"
              fontWeight={500}
              color="text.secondary"
            >
              {rule.label}
            </Typography>
          </Box>
        ))}
      </Box>

      <CustomButton
        buttonWidth={mobileView ? '100%' : '50%'}
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleSubmit(onSubmit)}
        disabled={isLoading}
        icon={<RightArrowIcon />}
      >
        Update Password
      </CustomButton>
      <SnackbarCustom
        open={!!snackbarMessage}
        onClose={handleCloseSnackbar}
        message={snackbarMessage || ''}
        severity={
          snackbarError
            ? SnackbarSeverityEnum.ERROR
            : SnackbarSeverityEnum.SUCCESS
        }
      />
    </Box>
  );
};

export default UpdatePassword;
