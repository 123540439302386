import { createApi } from '@reduxjs/toolkit/query/react';
import { config } from '../../config/config';
import { baseQueryWithInterceptor } from '../utils/baseQueryWithInterceptor';
import { IPaginationResponse } from '../../interfaces/common.interface';
import {
  ISpeciality,
  IUniversity,
} from '../../interfaces/university-speciality.interface';

const universitySpecialityApi = createApi({
  reducerPath: 'universitySpecialityApi',
  baseQuery: baseQueryWithInterceptor(
    config.BASE_USER_MICROSERVICE_API_URL + '/university-speciality'
  ),
  tagTypes: ['Universities', 'Specialities'],

  endpoints: builder => ({
    getUniversities: builder.query<
      IPaginationResponse<IUniversity>,
      { page?: number; limit?: number; search?: string; sort?: 'asc' | 'desc' }
    >({
      query: ({ page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/universities`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['Universities'],
    }),

    getSpecialities: builder.query<
      IPaginationResponse<ISpeciality>,
      { page?: number; limit?: number; search?: string; sort?: 'asc' | 'desc' }
    >({
      query: ({ page = 1, limit = 10, search = '', sort = 'asc' }) => ({
        url: `/specialities`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['Specialities'],
    }),

    getSpecialitiesByUniversity: builder.query<
      IPaginationResponse<ISpeciality>,
      {
        universityId: string;
        page?: number;
        limit?: number;
        search?: string;
        sort?: 'asc' | 'desc';
      }
    >({
      query: ({
        universityId,
        page = 1,
        limit = 10,
        search = '',
        sort = 'asc',
      }) => ({
        url: `/university/${universityId}/specialities`,
        params: { page, limit, search, sort },
      }),
      providesTags: ['Specialities'],
    }),
  }),
});

export const {
  useGetUniversitiesQuery,
  useGetSpecialitiesQuery,
  useGetSpecialitiesByUniversityQuery,
} = universitySpecialityApi;

export default universitySpecialityApi;
