import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface MyFeedIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const MyFeedsIcon = ({ iconColor, active, ...svgProps }: MyFeedIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <rect
        x="5"
        y="4"
        width="18"
        height="20"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M8 9H20" stroke={color} strokeWidth="1.5" />
      <path d="M8 14H20" stroke={color} strokeWidth="1.5" />
      <path d="M8 19H14" stroke={color} strokeWidth="1.5" />
    </SvgIcon>
  );
};

export default MyFeedsIcon;
