import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useThemeContext } from '../../../theme/ThemeContextProvider';

interface ChatsIconProps extends SvgIconProps {
  iconColor?: string;
  active?: boolean;
}

const ChatsIcon = ({ iconColor, active, ...svgProps }: ChatsIconProps) => {
  const { theme } = useThemeContext();

  const color = active
    ? theme.palette.button.primary
    : theme.palette.text.secondary;

  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28 28"
      sx={{
        width: svgProps.width ?? 28,
        height: svgProps.height ?? 28,
        fill: 'none',
        '& > path': {
          stroke: `${iconColor} !important`,
        },
      }}
      {...svgProps}
    >
      <path
        d="M4 6C4 5 5 4 6 4H22C23 4 24 5 24 6V18C24 19 23 20 22 20H9L4 24V6Z"
        stroke={color}
        strokeWidth="1.5"
      />
      <path d="M8 10H20" stroke={color} strokeWidth="1.5" />
      <path d="M8 14H16" stroke={color} strokeWidth="1.5" />
    </SvgIcon>
  );
};

export default ChatsIcon;
